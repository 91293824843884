<template>
  <!-- Dialog Add Revenue Streams -->
  <div>
    <ib-dialog
      id="dialog-story-mode-add-revenue-stream"
      empty-header
      :visible="dialogAddRevenueStreamVisible"
      fullscreen
      @open="openDialog"
      @close="closeDialog"
      @closed="closedDialog"
    >
      <!-- Mobile title -->
      <template #title>
        <ib-dialog-container class="d-md-none">
          <h2>
            {{ $tc('addRevenueStream') }}
          </h2>
        </ib-dialog-container>
      </template>
      <!-- /Mobile title -->

      <!-- Content Wrapper -->
      <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
        <!-- Header -->
        <add-dialog-header>
          <template #left>
            <!-- Title -->
            <h2 class="m-0">
              {{ $tc('addRevenueStream') }}
            </h2>
            <!-- /Title -->
          </template>

        </add-dialog-header>
        <!-- /Header -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === ''"
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.default.title')"
          :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.default.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'product'"
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.unitSales.title')"
          :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.unitSales.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'service'"
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.chargePerService.title')"
          :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.chargePerService.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'bl'"
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.billableHours.title')"
          :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.billableHours.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'or'"
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.occupancyRate.title')"
          :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.occupancyRate.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Info Guide -->
        <info-guide-add-dialog
          v-if="form.type === 'rc'"
          class="mb-3 d-block d-xl-none"
          :visible="guideVisible"
          :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.subscriptionModel.title')"
          :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.subscriptionModel.text')"
          @click="toggleDialogGuide"
        />
        <!-- Info Guide -->

        <!-- Ai Buddy -->
        <ai-buddy
          v-if="$breakpoint.lgAndDown"
          type="revenue-streams"
          class="ai-buddy-margin-bottom d-block d-xl-none"
          @suggestion="setSuggestion"
          @click="guideVisible = false"
        />
        <!-- Ai Buddy -->

        <!-- Inner Wrapper -->
        <div class="inner-wrapper">
          <div class="grid-wrapper">
            <el-row :gutter="20">

              <!-- Left Side -->
              <el-col :md="12" :xl="7">
                <!-- Form Wrapper -->
                <div class="revenue-form-wrapper">

                  <!-- Form Inner Top-->
                  <el-form class="revenue-form-inner-top">

                    <!-- Name -->
                    <div class="d-flex align-items-center">
                      <ib-input
                        v-model="form.name"
                        class="flex-grow-1"
                        :label="$t('fields.name')"
                      />
                      <ai-buddy-button-rounded
                        class="ai-buddy ml-2"
                        emit-event-buss-events
                        animation-key="roundedButtonAddModal"
                      />
                    </div>
                    <!-- /Name -->

                    <!-- Revenue Streams Type-->
                    <el-form-item
                      class="input-inside-label icon"
                      :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.typeOfRevenueStream')"
                      :class="{'focused' : focus.type}"
                    >
                      <span class="symbol" :class="form.type" />

                      <!-- Revenue Streams Type Select -->
                      <ib-select
                        v-model="form.type"
                        popper-class="promo-dropdown"
                        placeholder=" "
                        @change="onChangeType"
                      >

                        <!-- Revenue Streams Type Group Items -->
                        <ib-option
                          v-for="revenueOption in revenueOptions"
                          :key="revenueOption.value"
                          :label="revenueOption.label"
                          :value="revenueOption.value"
                        >

                          <!-- Detailed Option -->
                          <div class="detailed-option" :class="{'selected' : revenueOption.selected}">
                            <div class="flex">

                              <!-- Image -->
                              <img :src="revenueOption.imgPath" svg-inline class="icon-dropdown">
                              <!-- /Image -->

                              <!-- Label -->
                              <span class="label">{{ revenueOption.label }}</span>
                              <!-- /Label -->
                            </div>

                            <!-- Description -->
                            <span class="desc">{{ revenueOption.desc }}</span>
                            <!-- /Description -->

                          </div>
                          <!-- Detailed Option -->

                        </ib-option>
                        <!-- /Revenue Streams Type Group Items -->
                      </ib-select>
                      <!-- /Revenue Streams Type Select -->

                    </el-form-item>
                    <!-- /Revenue Streams Type-->

                  </el-form>
                  <!-- /Form Inner Top-->

                  <!-- Product And Service -->
                  <div v-if="form.type === 'product' || form.type === 'service'" class="product">

                    <!-- Form Inner Top -->
                    <div class="revenue-form-inner-top">

                      <!-- Form -->
                      <el-form>
                        <!-- Overall switcher -->
                        <form-switcher
                          v-model="form.isOverall"
                          class="my-2"
                          :options="unitSalesAndChargePerServiceOptions"
                          @change="toggleIsOverall"
                        />
                        <!-- /Overall switcher -->

                        <!-- Selling Price, Overall Revenue And Intervals -->
                        <div class="group-product">

                          <!-- Selling Price -->
                          <el-form-item
                            v-if="!form.isOverall"
                            class="input-inside-label wider"
                            :label="`${$t('pages.businessGuide.revenueStreams.addDialog.fields.sellingPrice')} (${currencySymbol})`"
                            :class="{'focused': focus.sellingPrice}"
                          >
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.sellingPrice"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('sellingPrice')"
                                @blur="toggleFocus('sellingPrice')"
                              />
                            </div>
                          </el-form-item>
                          <!-- /Selling Price -->

                          <!-- Overall Revenue -->
                          <el-form-item
                            v-if="form.isOverall"
                            class="input-inside-label"
                            :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.overallRevenue')"
                            :class="{'focused': focus.overallRevenue}"
                          >

                            <!-- Overall Revenue Input-->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.overallRevenue"
                                class="el-input__inner overall-revenue"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('overallRevenue')"
                                @blur="toggleFocus('overallRevenue')"
                              />
                            </div>
                            <!-- /Overall Revenue Input-->

                          </el-form-item>
                          <!-- /Overall Revenue -->

                          <!-- Overall Revenue Interval -->
                          <el-form-item
                            v-if="form.isOverall"
                            class="input-inside-label"
                            label=""
                            :class="{'focused' : focus.overallRevenueInterval}"
                          >

                            <!-- Overall Revenue Interval Select -->
                            <ib-select
                              v-model="form.overallRevenueInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('overallRevenueInterval')"
                              @blur="toggleFocus('overallRevenueInterval')"
                              @change="setMultipleIntervals(form.overallRevenueInterval, 'growthInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Overall Revenue Interval Select -->

                          </el-form-item>
                          <!-- /Overall Revenue Interval -->
                        </div>
                        <!-- /Selling Price, Overall Revenue And Intervals -->

                        <!-- Price Change And Interval -->
                        <div v-if="!form.isOverall" class="group-product switches">

                          <!-- Price Change -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.priceChange')"
                            :class="{'focused': focus.priceIncrease}"
                          >
                            <!-- Price Change Input-->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.priceIncrease"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isPriceIncreasePercentage ? 100 : form.isPriceIncreaseNegative && form.priceIncrease > form.sellingPrice ? form.sellingPrice : 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('priceIncrease')"
                                @blur="toggleFocus('priceIncrease')"
                              />
                            </div>
                            <!-- /Price Change Input-->

                            <!-- Price Change Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isPriceIncreaseNegative = !form.isPriceIncreaseNegative">
                                <i :class="form.isPriceIncreaseNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Change Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- /Price Change -->

                          <!-- Price Change Interval And Payment Option Toggle Button -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.priceIncreaseInterval}"
                          >
                            <!-- Price Change Interval Select-->
                            <ib-select
                              v-model="form.priceIncreaseInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('priceIncreaseInterval')"
                              @blur="toggleFocus('priceIncreaseInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Price Change Interval Select-->

                            <!-- Price Change Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isPriceIncreasePercentage')">
                                <i v-if="form.isPriceIncreasePercentage" class="el-icon">%</i>
                                <i v-else
                                   class="currency-symbol"
                                   :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                                >
                                  {{ currencySymbol }}
                                </i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Change Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- /Price Change Interval And Payment Option Toggle Button -->

                        </div>
                        <!-- /Price Change And Interval -->

                        <!-- Sales Volume And Interval -->
                        <div v-if="!form.isOverall" class="group-product">
                          <!-- Sales Volume -->
                          <el-form-item
                            class="input-inside-label"
                            :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.salesVolume')"
                            :class="{'focused': focus.salesVolume}"
                          >

                            <!-- Sales Volume Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.salesVolume"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: 999999999}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('salesVolume')"
                                @blur="toggleFocus('salesVolume')"
                              />
                            </div>
                            <!-- /Sales Volume Input -->

                          </el-form-item>
                          <!-- /Sales Volume -->

                          <!-- Sales Volume Interval -->
                          <el-form-item
                            class="input-inside-label"
                            label=""
                            :class="{'focused' : focus.salesVolumeInterval}"
                          >
                            <!-- Sales Volume Interval Select-->
                            <ib-select
                              v-model="form.salesVolumeInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('salesVolumeInterval')"
                              @blur="toggleFocus('salesVolumeInterval')"
                              @change="setMultipleIntervals(form.salesVolumeInterval, 'growthInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Sales Volume Interval Select -->

                          </el-form-item>
                          <!-- /Sales Volume Interval -->

                        </div>
                        <!-- /Sales Volume And Interval -->

                        <!-- Sales Growth And Interval -->
                        <div v-if="!form.isOverall" class="group-product switches">

                          <!-- Sales Growth -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.salesGrowth')"
                            :class="{'focused': focus.growth}"
                          >

                            <!-- Sales Growth Input-->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.growth"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isGrowthPercentage ? 100 : form.isGrowthNegative && form.growth > form.salesVolume ? form.salesVolume : 999999999}"
                                :precision="form.isGrowthPercentage ? {min: 0, max: 2} : 0"
                                locale="en-US"
                                @focus="toggleFocus('growth')"
                                @blur="toggleFocus('growth')"
                              />
                            </div>
                            <!-- /Sales Growth Input -->

                            <!-- Sales Growth Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isGrowthNegative = !form.isGrowthNegative">
                                <i :class="form.isGrowthNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Sales Growth Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- /Sales Growth -->

                          <!-- Sales Growth Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.growthInterval}"
                          >
                            <!-- Sales Growth Interval Select -->
                            <ib-select
                              v-model="form.growthInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('growthInterval')"
                              @blur="toggleFocus('growthInterval')"
                              @change="setMultipleIntervals(form.growthInterval, 'salesVolumeInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Sales Growth Interval Select -->

                            <!-- Price Change Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isGrowthPercentage')">
                                <i v-if="form.isGrowthPercentage" class="el-icon">%</i>
                                <i v-else class="currency-symbol">#</i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Change Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- /Sales Growth Interval -->

                        </div>
                        <!-- /Sales Growth And Interval -->

                        <!-- Revenue Increase And Interval -->
                        <div v-if="form.isOverall" class="group-product switches">

                          <!-- Revenue Increase -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.revenueIncrease')"
                            :class="{'focused': focus.growth}"
                          >

                            <!-- Revenue Increase Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.growth"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isGrowthPercentage ? 100 : form.isGrowthNegative && form.growth > form.overallRevenue ? form.overallRevenue : 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('growth')"
                                @blur="toggleFocus('growth')"
                              />
                            </div>
                            <!-- /Revenue Increase Input -->

                            <!-- Revenue Increase Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isGrowthNegative = !form.isGrowthNegative">
                                <i :class="form.isGrowthNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Revenue Increase Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- /Revenue Increase -->

                          <!-- Revenue Increase Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.growthInterval}"
                          >
                            <!-- Revenue Increase Interval Select -->
                            <ib-select
                              v-model="form.growthInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('growthInterval')"
                              @blur="toggleFocus('growthInterval')"
                              @change="setMultipleIntervals(form.growthInterval, 'overallRevenueInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Revenue Increase Interval Select -->

                            <!-- Revenue Increase Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isGrowthPercentage')">
                                <i v-if="form.isGrowthPercentage" class="el-icon">%</i>
                                <i v-else
                                   class="currency-symbol"
                                   :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                                >
                                  {{ currencySymbol }}
                                </i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Revenue Increase Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- Revenue Increase Interval -->
                        </div>
                        <!-- /Revenue Increase And Interval -->

                        <!-- Starts And Ends Dates -->
                        <div class="group-product date">

                          <!-- Starts -->
                          <el-form-item
                            class="input-inside-label yellow"
                            :label="$t('starts')"
                            :class="{'focused': focus.starts}"
                          >
                            <el-date-picker
                              v-model="form.starts"
                              :clearable="false"
                              :editable="false"
                              popper-class="date-picker-monthly-dropdown-modal"
                              :picker-options="pickerOptionsStarts"
                              class="date-picker-monthly"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                              @change="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- /Starts -->

                          <!-- Ends -->
                          <el-form-item
                            class="input-inside-label yellow"
                            :label="$t('ends')"
                            :class="{'focused': focus.ends}"
                          >
                            <el-date-picker
                              v-model="form.ends"
                              :clearable="false"
                              :editable="false"
                              popper-class="date-picker-monthly-dropdown-modal ends"
                              :picker-options="pickerOptionsEnds"
                              class="date-picker-monthly"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                              @change="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- /Ends -->

                        </div>
                        <!-- /Starts And Ends Dates -->

                      </el-form>
                      <!-- /Form -->

                    </div>
                    <!-- /Form Inner Top-->

                  </div>
                  <!-- /Product And Service -->

                  <!-- Billable Rate -->
                  <div v-if="form.type === 'bl'" class="billable-rate">

                    <!-- Form Inner Top -->
                    <div class="revenue-form-inner-top">

                      <!-- Form -->
                      <el-form>
                        <!-- Billable Rate -->
                        <el-form-item
                          class="input-inside-label"
                          :label="`${$t('pages.businessGuide.revenueStreams.addDialog.fields.billableRate')} (${currencySymbol})`"
                          :class="{'focused': focus.billableRate}"
                        >
                          <div class="el-input">
                            <currency-input
                              v-model="form.billableRate"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('billableRate')"
                              @blur="toggleFocus('billableRate')"
                            />
                          </div>
                        </el-form-item>
                        <!-- /Billable Rate -->

                        <!-- Billable Rate Increase And Interval -->
                        <div class="group-billable switches">

                          <!-- Billable Rate Increase -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.rateIncrease')"
                            :class="{'focused': focus.priceIncrease}"
                          >

                            <!-- Billable Rate Increase Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.priceIncrease"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isPriceIncreasePercentage ? 100 : form.isPriceIncreaseNegative && form.priceIncrease > form.billableRate ? form.billableRate : 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('priceIncrease')"
                                @blur="toggleFocus('priceIncrease')"
                              />
                            </div>
                            <!-- /Billable Rate Increase Input -->

                            <!-- Billable Rate Increase Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isPriceIncreaseNegative = !form.isPriceIncreaseNegative">
                                <i :class="form.isPriceIncreaseNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Billable Rate Increase Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- Billable Rate Increase -->

                          <!-- Billable Rate Increase Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.priceIncreaseInterval}"
                          >

                            <!-- Billable Rate Increase Interval Select -->
                            <ib-select
                              v-model="form.priceIncreaseInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('priceIncreaseInterval')"
                              @blur="toggleFocus('priceIncreaseInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Billable Rate Increase Interval Select -->

                            <!-- Revenue Increase Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isPriceIncreasePercentage')">
                                <i v-if="form.isPriceIncreasePercentage" class="el-icon">%</i>
                                <i v-else
                                   class="currency-symbol"
                                   :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                                >
                                  {{ currencySymbol }}
                                </i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Revenue Increase Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- /Billable Rate Increase Interval -->

                        </div>
                        <!-- /Billable Rate Increase And Interval  -->

                        <!-- Served And Interval -->
                        <div class="group-billable">

                          <!-- Served -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.served')"
                            :class="{'focused': focus.served}"
                          >

                            <!-- Served Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.served"
                                class="el-input__inner served"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: 999999999}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('served')"
                                @blur="toggleFocus('served')"
                              />
                            </div>
                            <!-- /Served Input -->

                          </el-form-item>
                          <!-- Served -->

                          <!-- Served Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.servedInterval}"
                          >

                            <!-- Served Interval Select -->
                            <ib-select
                              v-model="form.servedInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('servedInterval')"
                              @blur="toggleFocus('servedInterval')"
                              @change="setMultipleIntervals(form.servedInterval, 'growthInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Served Interval Select -->

                          </el-form-item>
                          <!-- /Served Interval -->

                        </div>
                        <!-- /Served And Interval  -->

                        <!-- Billing Rate Growth And Interval -->
                        <div class="group-billable switches">

                          <!-- Billing Rate Growth -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.growth')"
                            :class="{'focused': focus.growth}"
                          >

                            <!-- Billing Rate Growth Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.growth"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isGrowthPercentage ? 100 : form.isGrowthNegative && form.growth > form.served ? form.served : 999999999}"
                                :precision="form.isGrowthPercentage ? {min: 0, max: 2} : 0"
                                locale="en-US"
                                @focus="toggleFocus('growth')"
                                @blur="toggleFocus('growth')"
                              />
                            </div>
                            <!--/ Billing Rate Growth Input -->

                            <!-- Billing Rate Growth Increase Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isGrowthNegative = !form.isGrowthNegative">
                                <i :class="form.isGrowthNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Billing Rate Growth Increase Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- /Billing Rate Growth -->

                          <!-- Billing Rate Growth Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.growthInterval}"
                          >
                            <!-- Billing Rate Growth Interval Select -->
                            <ib-select
                              v-model="form.growthInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('growthInterval')"
                              @blur="toggleFocus('growthInterval')"
                              @change="setMultipleIntervals(form.growthInterval, 'servedInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Billing Rate Growth Interval Select -->

                            <!-- Billing Rate Growth Increase Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isGrowthPercentage')">
                                <i v-if="form.isGrowthPercentage" class="el-icon">%</i>
                                <i v-else class="currency-symbol">#</i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Billing Rate Growth Increase Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- /Billing Rate Growth Interval -->

                        </div>
                        <!-- /Billing Rate Growth And Interval -->

                        <!-- Starts And Ends -->
                        <div class="group-billable date">

                          <!-- Starts -->
                          <el-form-item
                            class="input-inside-label yellow"
                            :label="$t('starts')" prop="date.value"
                            :class="{'focused': focus.starts}"
                          >
                            <el-date-picker
                              v-model="form.starts"
                              :clearable="false"
                              :editable="false"
                              popper-class="date-picker-monthly-dropdown-modal"

                              :picker-options="pickerOptionsStarts"
                              class="date-picker-monthly"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- /Starts -->

                          <!-- Ends -->
                          <el-form-item
                            class="input-inside-label yellow"
                            :label="$t('ends')" prop="date.value"
                            :class="{'focused': focus.ends}"
                          >
                            <el-date-picker
                              v-model="form.ends"
                              :clearable="false"
                              :editable="false"
                              popper-class="date-picker-monthly-dropdown-modal ends"

                              :picker-options="pickerOptionsEnds"
                              class="date-picker-monthly"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('ends')"
                              @blur="toggleFocus('ends')"
                            />
                          </el-form-item>
                          <!-- Ends -->

                        </div>
                        <!-- /Starts And Ends -->

                      </el-form>
                      <!-- /Form -->

                    </div>
                    <!-- /Form Inner Top -->

                  </div>
                  <!-- /Billable Rate -->

                  <!-- Occupancy Rate -->
                  <div v-if="form.type === 'or'" class="occupancy-rate">

                    <!-- Form Inner Top -->
                    <div class="revenue-form-inner-top">

                      <!-- Form -->
                      <el-form>

                        <!-- Name And Count -->
                        <div class="input-group visible group-occupancy name-and-count">
                          <!-- Count -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.count')"
                            :class="{'focused': focus.count}"
                          >
                            <div class="count el-input">
                              <currency-input
                                v-model="form.count"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: 999999999}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('count')"
                                @blur="toggleFocus('count')"
                              />
                            </div>
                          </el-form-item>
                          <!-- Count -->

                        </div>
                        <!-- /Name And Count -->

                        <!-- Average charge per Service Unit -->
                        <el-form-item
                          class="input-inside-label"
                          :label="`${$t('pages.businessGuide.revenueStreams.addDialog.fields.averageChargePerServiceUnit')} (${currencySymbol})`"
                          :class="{'focused': focus.averageChargePerUnit}"
                        >

                          <!-- Average charge per Service Unit Input-->
                          <div class="el-input">
                            <currency-input
                              v-model="form.averageChargePerUnit"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('averageChargePerUnit')"
                              @blur="toggleFocus('averageChargePerUnit')"
                            />
                          </div>
                          <!-- /Average charge per Service Unit Input-->

                        </el-form-item>
                        <!-- /Average charge per Service Unit -->

                        <!-- Price Increase And Interval -->
                        <div class="group-occupancy switches">

                          <!-- Price Increase -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.priceIncrease')"
                            :class="{'focused': focus.priceIncrease}"
                          >

                            <!-- Price Increase Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.priceIncrease"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isPriceIncreasePercentage ? 100 : form.isPriceIncreaseNegative && form.priceIncrease > form.averageChargePerUnit ? form.averageChargePerUnit : 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('priceIncrease')"
                                @blur="toggleFocus('priceIncrease')"
                              />
                            </div>
                            <!-- /Price Increase Input -->

                            <!-- Price Increase Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isPriceIncreaseNegative = !form.isPriceIncreaseNegative">
                                <i :class="form.isPriceIncreaseNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Increase Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- Price Increase -->

                          <!-- Price Increase Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.priceIncreaseInterval}"
                          >

                            <!-- Price Increase Interval Select -->
                            <ib-select
                              v-model="form.priceIncreaseInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('priceIncreaseInterval')"
                              @blur="toggleFocus('priceIncreaseInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Price Increase Interval Select -->

                            <!-- Price Increase Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isPriceIncreasePercentage')">
                                <i v-if="form.isPriceIncreasePercentage" class="el-icon">%</i>
                                <i v-else
                                   class="currency-symbol"
                                   :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                                >
                                  {{ currencySymbol }}
                                </i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Increase Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- /Price Increase Interval -->

                        </div>
                        <!-- /Price Increase And Interval -->

                        <!-- Max turnovers per Service Unit And Interval -->
                        <div class="group-occupancy">

                          <!-- Max turnovers per Service Unit -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.maxTurnoversPerUnit')"
                            :class="{'focused': focus.maxTurnoversPerUnit}"
                          >

                            <!-- Max turnovers per Service Unit Input-->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.maxTurnoversPerUnit"
                                class="el-input__inner max-turnovers-per-unit"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: 999999999}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('maxTurnoversPerUnit')"
                                @blur="toggleFocus('maxTurnoversPerUnit')"
                              />
                            </div>
                            <!-- /Max turnovers per Service Unit Input-->

                          </el-form-item>
                          <!-- /Max turnovers per Service Unit -->

                          <!-- Max turnovers per Service Unit Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.maxTurnoversPerUnitInterval}"
                          >

                            <!-- Max turnovers per Service Unit Interval Select -->
                            <ib-select
                              v-model="form.maxTurnoversPerUnitInterval"
                              popper-class="promo-dropdown"
                              placeholder="Daily"
                              @focus="toggleFocus('maxTurnoversPerUnitInterval')"
                              @blur="toggleFocus('maxTurnoversPerUnitInterval')"
                            >
                              <ib-option :label="$t('daily')" value="Daily" />
                              <ib-option :label="$t('weekly')" value="Weekly" />
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Max turnovers per Service Unit Interval Select -->

                          </el-form-item>
                          <!-- /Max turnovers per Service Unit Interval -->

                        </div>
                        <!-- Max turnovers per Service Unit And Interval -->

                        <!-- Occupancy Rate -->
                        <el-form-item class="currency-related-form-item" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.occupancyRate')">
                          <span class="currency-symbol">%</span>

                          <!-- Occupancy Rate Input-->
                          <div class="currency-related-input percentage el-input">
                            <currency-input
                              v-model="form.occupancyRate"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 100}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                            />
                          </div>
                          <!-- /Occupancy Rate Input-->

                        </el-form-item>
                        <!-- Occupancy Rate -->

                        <!-- Occupancy Growth And Interval -->
                        <div class="group-occupancy switches">

                          <!-- Occupancy Growth -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.occupancyGrowth')"
                            :class="{'focused': focus.growth}"
                          >

                            <!-- Occupancy Growth Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.growth"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :min="0"
                                :max="100"
                                :value-range="{min: 0, max: 100}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('growth')"
                                @blur="toggleFocus('growth')"
                              />
                            </div>
                            <!-- /Occupancy Growth Input -->

                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button
                                :icon="form.isGrowthNegative ? 'el-icon-minus' : 'el-icon-plus'"
                                @click="form.isGrowthNegative = !form.isGrowthNegative"
                              />
                            </ib-tooltip>
                          </el-form-item>
                          <!-- /Occupancy Growth -->

                          <!-- Occupancy Growth Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.growthInterval}"
                          >

                            <!-- Occupancy Growth IntervalSelect -->
                            <ib-select
                              v-model="form.growthInterval"
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('growthInterval')"
                              @blur="toggleFocus('growthInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Occupancy Growth IntervalSelect -->

                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipNoValuesYesPercentages')"
                            >
                              <el-button>
                                <i class="el-icon">%</i>
                              </el-button>
                            </ib-tooltip>
                          </el-form-item>
                          <!-- /Occupancy Growth Interval -->

                        </div>
                        <!-- /Occupancy Growth And Interval -->

                        <!-- Starts And Ends -->
                        <div class="group-occupancy date">

                          <!-- Starts -->
                          <el-form-item
                            class="input-inside-label yellow"
                            :label="$t('starts')"
                            prop="date.value"
                            :class="{'focused': focus.starts}"
                          >
                            <el-date-picker
                              v-model="form.starts"
                              popper-class="date-picker-monthly-dropdown-modal"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptionsStarts"
                              class="date-picker-monthly"
                              type="month"
                              :clearable="false"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- /Starts -->

                          <!-- Ends -->
                          <el-form-item
                            class="input-inside-label yellow" :label="$t('ends')" prop="date.value"
                            :class="{'focused': focus.ends}"
                          >
                            <el-date-picker
                              v-model="form.ends"
                              popper-class="date-picker-monthly-dropdown-modal ends"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptionsEnds"
                              class="date-picker-monthly"
                              type="month"
                              :clearable="false"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('ends')"
                              @blur="toggleFocus('ends')"
                            />
                          </el-form-item>
                          <!-- /Ends -->

                        </div>
                        <!-- Starts And Ends -->

                      </el-form>
                      <!-- /Form -->

                    </div>
                    <!-- /Form Inner Top -->

                    <!-- Form Inner Bottom -->
                    <!-- <div class="revenue-form-inner-bottom">-->
                    <!--   <el-button class="advanced">-->
                    <!--     Advanced Settings <sup>SOON</sup>-->
                    <!--   </el-button>-->
                    <!-- </div>-->
                    <!-- /Form Inner Bottom -->

                  </div>
                  <!-- /Occupancy Rate -->

                  <!-- Recurring Charges -->
                  <div v-if="form.type === 'rc'" class="recurring-charges">

                    <!-- Form Inner Top -->
                    <div class="revenue-form-inner-top">

                      <!-- Form -->
                      <el-form>
                        <!-- Upfront Fee -->
                        <el-form-item
                          class="input-inside-label"
                          :label="`${$t('pages.businessGuide.revenueStreams.addDialog.fields.upfrontFee')} (${currencySymbol})`"
                          :class="{'focused': focus.upfrontFee}"
                        >

                          <!-- Upfront Fee Input -->
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.upfrontFee"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('upfrontFee')"
                              @blur="toggleFocus('upfrontFee')"
                            />
                          </div>
                          <!-- /Upfront Fee Input -->

                        </el-form-item>
                        <!-- /Upfront Fee -->

                        <!-- Recurring Charge And Interval -->
                        <div class="group-recurring">

                          <!-- Recurring Charge -->
                          <el-form-item
                            class="input-inside-label"
                            :label="`${$t('pages.businessGuide.revenueStreams.addDialog.fields.recurringCharge')} (${currencySymbol})`"
                            :class="{'focused': focus.recurringCharge}"
                          >

                            <!-- Recurring Charge Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.recurringCharge"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('recurringCharge')"
                                @blur="toggleFocus('recurringCharge')"
                              />
                            </div>
                            <!-- /Recurring Charge Input -->

                          </el-form-item>
                          <!-- /Recurring Charge -->

                          <!-- Recurring Charge Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.recurringChargeInterval}"
                          >
                            <!-- Recurring Charge Interval Select -->
                            <ib-select
                              v-model="form.recurringChargeInterval"
                              disabled
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('recurringChargeInterval')"
                              @blur="toggleFocus('recurringChargeInterval')"
                              @change="setMultipleIntervals(form.recurringChargeInterval, 'priceIncreaseInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Recurring Charge Interval Select -->

                          </el-form-item>
                          <!-- /Recurring Charge Interval -->

                        </div>
                        <!-- /Recurring Charge And Interval -->

                        <!-- Price Increase And Interval -->
                        <div class="group-recurring switches">

                          <!-- Price Increase -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.priceIncrease')"
                            :class="{'focused': focus.priceIncrease}"
                          >

                            <!-- Price Increase Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.priceIncrease"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isPriceIncreasePercentage ? 100 : form.isPriceIncreaseNegative && form.priceIncrease > form.recurringCharge ? form.recurringCharge : 999999999}"
                                :precision="{min: 0, max: 2}"
                                locale="en-US"
                                @focus="toggleFocus('priceIncrease')"
                                @blur="toggleFocus('priceIncrease')"
                              />
                            </div>
                            <!-- /Price Increase Input -->

                            <!-- Price Increase Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isPriceIncreaseNegative = !form.isPriceIncreaseNegative">
                                <i :class="form.isPriceIncreaseNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Increase Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- /Price Increase -->

                          <!-- Price Increase Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.priceIncreaseInterval}"
                          >

                            <!-- Price Increase Interval Select -->
                            <ib-select
                              v-model="form.priceIncreaseInterval"
                              disabled
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('priceIncreaseInterval')"
                              @blur="toggleFocus('priceIncreaseInterval')"
                              @change="setMultipleIntervals(form.priceIncreaseInterval, 'recurringChargeInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- Price Increase Interval Select -->

                            <!-- Price Increase Payment Option Toggle Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isPriceIncreasePercentage')">
                                <i v-if="form.isPriceIncreasePercentage" class="el-icon">%</i>
                                <i v-else
                                   class="currency-symbol"
                                   :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                                >
                                  {{ currencySymbol }}
                                </i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Price Increase Payment Option Toggle Button -->

                          </el-form-item>
                          <!-- Price Increase Interval -->
                        </div>
                        <!-- /Price Increase And Interval -->

                        <!-- Number Of New Sign Ups And Interval -->
                        <div class="group-recurring">

                          <!-- Number Of New Sign Ups -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.numberOfNewSignUps')"
                            :class="{'focused': focus.newSignUps}"
                          >

                            <!-- Number Of New Sign Ups Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.newSignUps"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 1, max: 999999999}"
                                :precision="0"
                                locale="en-US"
                                @focus="toggleFocus('newSignUps')"
                                @blur="toggleFocus('newSignUps')"
                              />
                            </div>
                            <!-- /Number Of New Sign Ups Input -->

                          </el-form-item>
                          <!-- /Number Of New Sign Ups -->

                          <!-- Number Of New Sign Ups Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.newSignUpsInterval}"
                          >

                            <!-- Number Of New Sign Ups Interval Select -->
                            <ib-select
                              v-model="form.newSignUpsInterval"
                              disabled
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('newSignUpsInterval')"
                              @blur="toggleFocus('newSignUpsInterval')"
                              @change="setMultipleIntervals(form.newSignUpsInterval, 'growthInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Number Of New Sign Ups Interval Select -->

                          </el-form-item>
                          <!-- /Number Of New Sign Ups Interval -->

                        </div>
                        <!-- /Number Of New Sign Ups And Interval -->

                        <!-- Sign Ups Growth And Interval -->
                        <div class="group-recurring switches">

                          <!-- Sign Ups Growth -->
                          <el-form-item
                            class="input-inside-label" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.signUpsGrowth')"
                            :class="{'focused': focus.growth}"
                          >

                            <!-- Sign Ups Growth Input -->
                            <div class="input-with-select el-input">
                              <currency-input
                                v-model="form.growth"
                                class="el-input__inner"
                                :distraction-free="false"
                                :currency="null"
                                :value-range="{min: 0, max: form.isGrowthPercentage ? 100 : form.isGrowthNegative && form.growth > form.newSignUps ? form.newSignUps : 999999999}"
                                :precision="form.isGrowthPercentage ? {min: 0, max: 2} : 0"
                                locale="en-US"
                                @focus="toggleFocus('growth')"
                                @blur="toggleFocus('growth')"
                              />
                            </div>
                            <!-- /Sign Ups Growth Input -->

                            <!-- Sign Ups Growth Positive/Negative Switch Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipPositiveNegative')"
                            >
                              <el-button @click="form.isGrowthNegative = !form.isGrowthNegative">
                                <i :class="form.isGrowthNegative ? 'el-icon-minus' : 'el-icon-plus'" />
                              </el-button>
                            </ib-tooltip>
                            <!-- /Sign Ups Growth Positive/Negative Switch Button -->

                          </el-form-item>
                          <!-- /Sign Ups Growth -->

                          <!-- Sign Ups Growth Interval -->
                          <el-form-item
                            class="input-inside-label" label=""
                            :class="{'focused' : focus.growthInterval}"
                          >

                            <!-- Sign Ups Growth Interval Select -->
                            <ib-select
                              v-model="form.growthInterval"
                              disabled
                              popper-class="promo-dropdown"
                              :placeholder="$t('monthly')"
                              @focus="toggleFocus('growthInterval')"
                              @blur="toggleFocus('growthInterval')"
                              @change="setMultipleIntervals(form.growthInterval, 'newSignUpsInterval')"
                            >
                              <ib-option :label="$t('monthly')" value="Monthly" />
                              <ib-option :label="$t('quarterly')" value="Quarterly" />
                              <ib-option :label="$t('annually')" value="Annually" />
                            </ib-select>
                            <!-- /Sign Ups Growth Interval Select -->

                            <!-- Sign Ups Growth Toggle Percentage Button -->
                            <ib-tooltip
                              popper-class="center"
                              :content="$t('tooltipValuesPercentages')"
                            >
                              <el-button @click="togglePercentage('isGrowthPercentage')">
                                <i v-if="form.isGrowthPercentage" class="el-icon">%</i>
                                <i v-else class="currency-symbol">#</i>
                              </el-button>
                            </ib-tooltip>
                            <!-- /Sign Ups Growth Toggle Percentage Button -->

                          </el-form-item>
                          <!-- /Sign Ups Growth Interval -->

                        </div>
                        <!-- /Sign Ups Growth And Interval -->

                        <!-- Churn -->
                        <el-form-item class="currency-related-form-item" :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.churn')">

                          <!-- Churn Input -->
                          <div class="currency-related-input percentage el-input">
                            <currency-input
                              v-model="form.churn"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: 100}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                            />
                          </div>
                          <!-- /Churn Input -->

                          <span class="currency-symbol">%</span>
                        </el-form-item>
                        <!-- /Churn -->

                        <!-- Starts And Ends -->
                        <div class="group-recurring date">

                          <!-- Starts -->
                          <el-form-item
                            class="input-inside-label yellow date-picker"
                            :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.signUpStarts')"
                            :class="{'focused': focus.starts}"
                          >
                            <el-date-picker
                              v-model="form.starts"
                              popper-class="date-picker-monthly-dropdown-modal"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptionsStarts"
                              class="date-picker-monthly"
                              type="month"
                              :clearable="false"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- /Starts -->

                          <!-- Ends -->
                          <el-form-item
                            class="input-inside-label yellow date-picker"
                            :label="$t('pages.businessGuide.revenueStreams.addDialog.fields.signUpEnds')"
                            :class="{'focused': focus.ends}"
                          >
                            <el-date-picker
                              v-model="form.ends"
                              popper-class="date-picker-monthly-dropdown-modal ends"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptionsEnds"
                              class="date-picker-monthly"
                              type="month"
                              :clearable="false"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('ends')"
                              @blur="toggleFocus('ends')"
                            />
                          </el-form-item>
                          <!-- /Ends -->

                        </div>
                        <!-- /Starts And Ends -->

                      </el-form>
                      <!-- /Form -->

                    </div>
                    <!-- /Form Inner Top -->

                  </div>
                  <!-- /Recurring Charges -->

                </div>
                <!-- /Form Wrapper -->

                <!-- Close And Save -->
                <div class="my-4 my-md-0">

                  <!-- Divider -->
                  <ib-divider block class="my-4" />
                  <!-- /Divider -->

                  <el-row :gutter="10" class="d-flex">
                    <el-col :span="12">
                      <!-- Close -->
                      <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialogOnButton">
                        {{ $t('close') }}
                      </ib-button>
                      <!-- /Close -->
                    </el-col>

                    <el-col :span="12">
                      <ib-button
                        class="w-100 text-uppercase"
                        size="lg"
                        :disabled="!isAddEnabled"
                        :loading="loading"
                        @click="saveRevenueStream"
                      >
                        {{ form.id === 0 ? $t('add') : $t('save') }}
                      </ib-button>
                    </el-col>
                  </el-row>
                </div>

              </el-col>
              <!-- /Left Side -->

              <!-- Center -->
              <el-col :md="12" :xl="7">
                <template v-if="!$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams.length">
                  <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                    <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                    <p class="add-dialog-folder-text font-outfit-regular">
                      {{ $t('createdItemsWillAppearHere') }}
                    </p>
                  </div>
                </template>
                <!-- Revenue Streams Added -->
                <div ref="cardHolder" class="costs-added">

                  <!-- Revenue Streams Added List -->
                  <div
                    v-for="revenueStream in $store.state.idea.storyMode.forecast.revenueStreams.revenueStreams"
                    :key="revenueStream.id"
                  >
                    <set-up-and-projections-card
                      class="mb-2"
                      :entity="revenueStream"
                      :name="revenueStream.name"
                      :date="`${getFormattedDate(revenueStream.starts)} - ${getFormattedDate(revenueStream.ends)}`"
                      :amount="getFormattedAmount(revenueStream.average)"
                      :frequency="$t('pages.businessGuide.monthlyAverage')"
                      :type="revenueStream.type"
                      view="list"
                      :delete-disabled="form.id === revenueStream.id"
                      :edit-disabled="form.id === revenueStream.id"
                      @edit="onEditRevenueStream"
                      @copy="onCopyRevenueStream"
                      @delete="onDeleteRevenueStream"
                    />
                  </div>
                  <!-- /Revenue Streams Added List -->
                  <erase-icon-button
                    v-if="removeButtonVisible"
                    confirm
                    :loading="removeLoadingExamples"
                    @confirm="deleteExamples"
                  />
                </div>
                <!-- /Revenue Streams Added -->

              </el-col>
              <!-- /Center -->

              <!-- Right Side -->
              <el-col :xl="10">
                <!-- Info Guide -->
                <info-guide-add-dialog
                  v-if="form.type === ''"
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.default.title')"
                  :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.default.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Info Guide -->
                <info-guide-add-dialog
                  v-if="form.type === 'product'"
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.unitSales.title')"
                  :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.unitSales.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Info Guide -->
                <info-guide-add-dialog
                  v-if="form.type === 'service'"
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.chargePerService.title')"
                  :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.chargePerService.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Info Guide -->
                <info-guide-add-dialog
                  v-if="form.type === 'bl'"
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.billableHours.title')"
                  :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.billableHours.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Info Guide -->
                <info-guide-add-dialog
                  v-if="form.type === 'or'"
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.occupancyRate.title')"
                  :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.occupancyRate.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Info Guide -->
                <info-guide-add-dialog
                  v-if="form.type === 'rc'"
                  class="mb-3 d-none d-xl-block"
                  :visible="guideVisible"
                  :title="$t('pages.businessGuide.revenueStreams.addDialog.guide.subscriptionModel.title')"
                  :text="$t('pages.businessGuide.revenueStreams.addDialog.guide.subscriptionModel.text')"
                  @click="toggleDialogGuide"
                />
                <!-- Info Guide -->

                <!-- Ai Buddy -->
                <div class="relative">
                  <ai-buddy
                    v-if="$breakpoint.lgAndUp"
                    type="revenue-streams"
                    class="ai-buddy-margin-bottom d-none d-xl-block"
                    @suggestion="setSuggestion"
                    @click="handwritingSet('addRevenueStreamSuggestion'); guideVisible = false"
                  />
                  <!-- Ai Buddy -->
                  <div v-if="getHandwritingVisible('addRevenueStreamSuggestion')" class="d-none d-xl-block">
                    <AddDialogAiHandwriting />
                  </div>
                </div>

                <!-- Ai Buddy -->
              </el-col>
              <!-- /Right Side -->
            </el-row>
          </div>
        </div>
        <!-- /Inner Wrapper -->

      </ib-dialog-container>
      <!-- /Content Wrapper -->

    </ib-dialog>

    <!-- Dialog Remove Revenue Stream -->
    <dialog-remove-revenue-stream
      :dialog-remove-revenue-stream-visible="dialogRemoveRevenueStreamVisible"
      :revenue-stream-action-to-delete="revenueStreamActionToDelete"
      @close-dialog-remove-revenue-stream="onCloseDialogRemoveRevenueStream"
      @on-update-revenue-streams-view="$emit('on-update-revenue-streams-view')"
    />
    <!-- /Dialog Remove Revenue Stream -->

  </div>
  <!-- /Dialog Add Revenue Streams -->

</template>

<script>
import 'cropperjs/dist/cropper.css'
import AddDialogAiHandwriting from '@/views/Home/Components/AddDialogAiHandwriting.vue'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
import DialogRemoveRevenueStream from '@/views/Home/StoryMode/Forecast/RevenueStreams/Dialogs/DialogRemoveRevenueStream'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import FormSwitcher from '@/components/_v2/Form/FormSwitcher'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGamification from '@/mixins/gamification'
import MixinGuide from '@/mixins/guide'
import MixinHandwriting from '@/mixins/handwriting'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinRevenueStreams from '@/mixins/revenueStreams'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'
import { mapGetters } from 'vuex'

export default {
  name: 'DialogAddRevenueStream',

  components: {
    AddDialogAiHandwriting,
    AiBuddy,
    AiBuddyButtonRounded,
    InfoGuideAddDialog,
    FormSwitcher,
    EraseIconButton,
    AddDialogHeader,
    DialogRemoveRevenueStream,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCurrencies,
    MixinDialog,
    MixinRevenueStreams,
    MixinGuide,
    MixinCloseMenu,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinGamification,
    MixinHandwriting
  ],

  props: {
    dialogAddRevenueStreamVisible: {
      type: Boolean,
      default: false
    },

    revenueStreamAction: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      dialogRemoveRevenueStreamVisible: false,
      revenueStreamActionToDelete: null,
      loading: false,
      removeLoadingExamples: false,
      form: {
        averageChargePerUnit: null,
        billableRate: null,
        churn: null,
        count: null,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        growth: null,
        growthInterval: 'Monthly',
        id: 0,
        ideaId: this.$store.state.idea.id,
        isGrowthNegative: false,
        isGrowthPercentage: false,
        isOverall: false,
        isPriceIncreaseNegative: false,
        isPriceIncreasePercentage: false,
        isTaxable: false,
        maxTurnoversPerUnit: null,
        maxTurnoversPerUnitInterval: 'Daily',
        name: '',
        newSignUps: null,
        newSignUpsInterval: 'Monthly',
        occupancyRate: 100,
        overallRevenue: null,
        overallRevenueInterval: 'Monthly',
        priceIncrease: null,
        priceIncreaseInterval: 'Monthly',
        recurringCharge: null,
        recurringChargeInterval: 'Monthly',
        salesVolume: null,
        salesVolumeInterval: 'Monthly',
        sellingPrice: null,
        served: null,
        servedInterval: 'Monthly',
        starts: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('YYYY-MM-DD'),
        type: '',
        upfrontFee: null
      },

      focus: {
        name: false,
        type: false,
        isOverall: false,
        sellingPrice: false,
        salesVolume: false,
        salesVolumeInterval: false,
        overallRevenue: false,
        overallRevenueInterval: false,
        billableRate: false,
        served: false,
        servedInterval: false,
        count: false,
        averageChargePerUnit: false,
        maxTurnoversPerUnit: false,
        maxTurnoversPerUnitInterval: false,
        occupancyRate: false,
        upfrontFee: false,
        recurringCharge: false,
        recurringChargeInterval: false,
        newSignUps: false,
        newSignUpsInterval: false,
        churn: false,
        priceIncrease: false,
        priceIncreasePrefix: false,
        priceIncreaseInterval: false,
        isPriceIncreasePercentage: false,
        growth: false,
        growthPrefix: false,
        growthInterval: false,
        isGrowthPercentage: false,
        starts: false,
        ends: false
      },
      revenueOptions: [
        {
          id: 1,
          selected: false,
          label: this.$t('pages.businessGuide.revenueStreams.addDialog.fields.unitSales'),
          value: 'product',
          imgPath: require('@/assets/img/icons/story-mode/revenue-stream/c_product.svg'),
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          id: 2,
          selected: false,
          label: this.$t('pages.businessGuide.revenueStreams.addDialog.fields.chargePerService'),
          value: 'service',
          imgPath: require('@/assets/img/icons/story-mode/revenue-stream/c_service.svg'),
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          id: 3,
          selected: false,
          label: this.$t('pages.businessGuide.revenueStreams.addDialog.fields.billableHours'),
          value: 'bl',
          imgPath: require('@/assets/img/icons/story-mode/revenue-stream/c_billable rate.svg'),
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          id: 4,
          selected: false,
          label: this.$t('pages.businessGuide.revenueStreams.addDialog.fields.occupancyRate'),
          value: 'or',
          imgPath: require('@/assets/img/icons/story-mode/revenue-stream/c_occupancy rate.svg'),
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          id: 5,
          selected: false,
          label: this.$t('pages.businessGuide.revenueStreams.addDialog.fields.subscriptionModel'),
          value: 'rc',
          imgPath: require('@/assets/img/icons/story-mode/revenue-stream/c_recurring charges.svg'),
          desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        }
      ],
      guideVisible: false,
      expandedDialogGuide: true,
      formIndependent: null
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    unitSalesAndChargePerServiceOptions () {
      return [
        {
          title: this.form.type === 'product' ? this.$t('pages.businessGuide.revenueStreams.perUnit') : this.$t('pages.businessGuide.revenueStreams.perService'),
          value: false
        },
        {
          title: this.$t('pages.businessGuide.revenueStreams.totalRevenue'),
          value: true
        }
      ]
    },

    isAddEnabled () {
      let enabled
      switch (this.form.type) {
        case 'product':
        case 'service':
          if (!this.form.isOverall) {
            enabled = this.form.name && this.form.sellingPrice && this.form.salesVolume && this.form.starts && this.form.ends
          } else {
            enabled = this.form.name && this.form.overallRevenue && this.form.starts && this.form.ends
          }
          break
        case 'bl':
          enabled = this.form.name && this.form.billableRate && this.form.served && this.form.starts && this.form.ends
          break
        case 'or':
          enabled = this.form.name && this.form.count && this.form.averageChargePerUnit && this.form.maxTurnoversPerUnit && this.form.occupancyRate && this.form.starts && this.form.ends
          break
        case 'rc':
          enabled = this.form.name && this.form.recurringCharge && this.form.newSignUps && this.form.starts && this.form.ends
          break
        default:
          enabled = false
      }

      return enabled
    },

    pickerOptionsStarts () {
      const maxOccurDate = this.form.ends ? this.$moment(this.form.ends).format('x') : this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
      const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

      return {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() > maxOccurDate
        }
      }
    },

    pickerOptionsEnds () {
      const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
      const minOccurDate = this.form.starts ? this.$moment(this.form.starts).format('x') : this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

      return {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      }
    },

    isDialogOpen () {
      return this.dialogAddRevenueStreamVisible
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getRevenueStreams')
    }
  },

  watch: {
    'form.starts' (newValue, oldValue) {
      if (newValue < this.form.ends) {
        const firstDayOfMonth = this.$moment(oldValue).startOf('month').format('YYYY-MM-DD')
        if (this.form.starts <= firstDayOfMonth) {
          const oldValueDate = this.$moment(oldValue).format('YYYY-MM-DD')
          this.form.ends = this.$moment(oldValueDate).startOf('month').format('YYYY-MM-DD')
        }
      }
      if (this.form.ends === newValue) {
        const selectedDate = this.$moment(newValue).format('YYYY-MM-DD')
        this.form.ends = this.$moment(selectedDate).endOf('month').format('YYYY-MM-DD')
      }
    },

    'form.ends' (newValue) {
      if (this.form.starts === newValue) {
        const selectedDate = this.$moment(newValue).format('YYYY-MM-DD')
        this.form.ends = this.$moment(selectedDate).endOf('month').format('YYYY-MM-DD')
      }
    }
  },

  methods: {
    setSuggestion (suggestion) {
      const types = {
        unit_sales: 'product',
        charge_per_service: 'service',
        billable_hours: 'bl',
        subscription_model: 'rc'
      }

      this.form.name = suggestion.name
      this.form.type = types[suggestion.revenue_type]
      this.focus.type = true

      if (['unit_sales', 'charge_per_service', 'billable_hours'].includes(suggestion.revenue_type)) {
        this.form.sellingPrice = Number(suggestion.selling_price)
        this.form.salesVolume = Number(suggestion.monthly_sales_volume)
        this.focus.sellingPrice = true
        this.focus.salesVolume = true
      }

      if (suggestion.revenue_type === 'subscription_model') {
        this.form.recurringCharge = Number(suggestion.monthly_recurring_charge)
        this.form.newSignUps = Number(suggestion.monthly_number_of_sign_ups)
        this.form.churn = Number(suggestion.monthly_churn_in_percentage)
        this.focus.recurringCharge = true
        this.focus.newSignUps = true
        this.focus.churn = true
      }
    },

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-revenue-streams')
    },

    closedDialog () {
      this.resetForm()
      this.closeDialog()
      this.form.name = ''
      this.guideVisible = false
    },

    toggleIsOverall () {
      this.form.overallRevenueInterval = this.form.isOverall ? this.form.growthInterval : this.form.overallRevenueInterval
      this.form.salesVolumeInterval = this.form.isOverall ? this.form.salesVolumeInterval : this.form.growthInterval
    },

    toggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    toggleFocus (input) {
      this.focus[input] = !this.focus[input]
      if (this.form[input] !== null && this.form[input] !== '') this.focus[input] = true
    },

    setMultipleIntervals (value, targetIntervalName) {
      this.form[targetIntervalName] = value
    },

    togglePercentage (percentageName) {
      this.form[percentageName] = !this.form[percentageName]
    },

    openDialog () {
      this.openGuideDialog('addRevenueStreamGuide')
      this.resetForm()
      if (this.revenueStreamAction) {
        this.form = Object.assign({}, this.revenueStreamAction)
        this.form.ideaId = this.$store.state.idea.id
      }
      this.checkInputLabels()
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    onChangeType () {
      this.toggleFocus('type')
      const revenueStreamType = this.form.type
      this.resetForm()
      this.form.type = revenueStreamType
      this.checkInputLabels()
    },

    checkInputLabels () {
      this.checkInputLength('name')
      this.checkInputLength('type')
      this.checkInputLength('isOverall')
      this.checkInputLength('sellingPrice')
      this.checkInputLength('salesVolume')
      this.checkInputLength('salesVolumeInterval')
      this.checkInputLength('overallRevenue')
      this.checkInputLength('overallRevenueInterval')
      this.checkInputLength('billableRate')
      this.checkInputLength('served')
      this.checkInputLength('servedInterval')
      this.checkInputLength('count')
      this.checkInputLength('averageChargePerUnit')
      this.checkInputLength('maxTurnoversPerUnit')
      this.checkInputLength('maxTurnoversPerUnitInterval')
      this.checkInputLength('occupancyRate')
      this.checkInputLength('upfrontFee')
      this.checkInputLength('recurringCharge')
      this.checkInputLength('recurringChargeInterval')
      this.checkInputLength('newSignUps')
      this.checkInputLength('newSignUpsInterval')
      this.checkInputLength('churn')
      this.checkInputLength('priceIncrease')
      this.checkInputLength('priceIncreasePrefix')
      this.checkInputLength('priceIncreaseInterval')
      this.checkInputLength('isPriceIncreasePercentage')
      this.checkInputLength('growth')
      this.checkInputLength('growthPrefix')
      this.checkInputLength('growthInterval')
      this.checkInputLength('isGrowthPercentage')
      this.checkInputLength('starts')
      this.checkInputLength('ends')
    },

    checkInputLength (input) {
      this.focus[input] = this.form[input] !== null && this.form[input] !== ''
    },

    resetForm () {
      this.form = {
        averageChargePerUnit: null,
        billableRate: null,
        churn: null,
        count: null,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        growth: null,
        growthInterval: 'Monthly',
        id: 0,
        ideaId: this.$store.state.idea.id,
        isGrowthNegative: false,
        isGrowthPercentage: false,
        isOverall: false,
        isPriceIncreaseNegative: false,
        isPriceIncreasePercentage: false,
        isTaxable: false,
        maxTurnoversPerUnit: null,
        maxTurnoversPerUnitInterval: 'Daily',
        name: this.form.name,
        newSignUps: null,
        newSignUpsInterval: 'Monthly',
        occupancyRate: 100,
        overallRevenue: null,
        overallRevenueInterval: 'Monthly',
        priceIncrease: null,
        priceIncreaseInterval: 'Monthly',
        recurringCharge: null,
        recurringChargeInterval: 'Monthly',
        salesVolume: null,
        salesVolumeInterval: 'Monthly',
        sellingPrice: null,
        served: null,
        servedInterval: 'Monthly',
        starts: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('YYYY-MM-DD'),
        type: '',
        upfrontFee: null
      }

      this.focus = {
        averageChargePerUnit: false,
        billableRate: false,
        churn: false,
        count: false,
        ends: false,
        growth: false,
        growthInterval: false,
        growthPrefix: false,
        isGrowthPercentage: false,
        isOverall: false,
        isPriceIncreasePercentage: false,
        maxTurnoversPerUnit: false,
        maxTurnoversPerUnitInterval: false,
        name: false,
        newSignUps: false,
        newSignUpsInterval: false,
        occupancyRate: false,
        overallRevenue: false,
        overallRevenueInterval: false,
        priceIncrease: false,
        priceIncreaseInterval: false,
        priceIncreasePrefix: false,
        recurringCharge: false,
        recurringChargeInterval: false,
        salesVolume: false,
        salesVolumeInterval: false,
        sellingPrice: false,
        served: false,
        servedInterval: false,
        starts: false,
        type: false,
        upfrontFee: false
      }

      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getRevenueStreams'].length === 0) {
        this.guideVisible = false
      }
    },

    saveRevenueStream () {
      this.loading = true
      if (this.form.id === 0) {
        this.addRevenueStream()
      } else {
        this.updateRevenueStream()
      }
    },

    addRevenueStream () {
      this.$http.post('story-mode/forecast/revenue-stream/revenue-stream', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addRevenueStream', response.data.payload.revenueStream)
            this.handleGamification()
            this.resetForm()
            this.form.name = ''
          }
          this.$emit('on-update-revenue-streams-view')
        }).finally(() => {
          this.loading = false
          this.resetForm()
          this.scrollToTop()
        })
    },

    updateRevenueStream () {
      this.$http.put(`story-mode/forecast/revenue-stream/revenue-stream/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            const revenueStream = response.data.payload.revenueStream

            this.$store.commit('idea/updateRevenueStream', revenueStream)
            this.updateRelatedEntities(revenueStream)
            this.resetForm()
            this.form.name = ''
          }
          this.$emit('on-update-revenue-streams-view')
        }).finally(() => {
          this.loading = false
          this.resetForm()
          this.scrollToTop()
        })
    },

    updateRelatedEntities (revenueStream) {
      this.$store.state.idea.storyMode.forecast.otherOverheads.otherOverheads.map(
        otherOverhead => this.updateEntityAction(otherOverhead, revenueStream, 'idea/updateOtherOverhead')
      )

      this.$store.state.idea.storyMode.forecast.directCosts.directCosts.map(
        directCost => this.updateEntityAction(directCost, revenueStream, 'idea/updateDirectCost')
      )

      this.$store.state.idea.storyMode.forecast.marketingBudget.marketingElements.map(
        marketingElement => this.updateEntityAction(marketingElement, revenueStream, 'idea/updateMarketingElement')
      )
    },

    updateEntityAction (entity, revenueStream, action) {
      if (entity.revenueStreamId && entity.revenueStreamId === revenueStream.id) {
        const entityCopy = { ...entity }
        entityCopy.starts = revenueStream.starts
        entityCopy.ends = revenueStream.ends
        this.$store.commit(action, entityCopy)
      }
    },

    onDeleteRevenueStream (revenueStream) {
      this.revenueStreamActionToDelete = Object.assign({}, revenueStream)
      this.dialogRemoveRevenueStreamVisible = true
    },

    onCloseDialogRemoveRevenueStream () {
      this.dialogRemoveRevenueStreamVisible = false
    },

    onCopyRevenueStream (revenueStream) {
      this.copyRevenueStreamToForm(revenueStream)
      this.form.id = 0
    },

    copyRevenueStreamToForm (revenueStream) {
      this.resetForm()
      this.form = Object.assign({}, revenueStream)
      this.form.ideaId = this.$store.state.idea.id
      this.checkInputLabels()
      this.scrollToTop()
    },

    onEditRevenueStream (revenueStream) {
      this.copyRevenueStreamToForm(revenueStream)
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('revenue-streams')
        this.$emit('on-update-revenue-streams-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
