<template>
  <!-- Dialog Add Other Overhead -->
  <ib-dialog
    id="dialog-story-mode-add-other-overhead"
    empty-header
    :visible="dialogAddOtherOverheadVisible"
    fullscreen
    @open="openDialog"
    @close="closeDialog"
    @closed="closedDialog"
  >
    <!-- Mobile title -->
    <template #title>
      <ib-dialog-container class="d-md-none">
        <h2>
          {{ $tc('addOverHead') }}
        </h2>
      </ib-dialog-container>
    </template>
    <!-- /Mobile title -->

    <!-- Content Wrapper -->
    <ib-dialog-container class="content-wrapper px-1" max-width="1920px">
      <!-- Header -->
      <add-dialog-header>
        <template #left>
          <!-- Title -->
          <h2 class="m-0">
            {{ $tc('addOverHead') }}
          </h2>
          <!-- /Title -->
        </template>
      </add-dialog-header>
      <!-- /Header -->

      <!-- Info Guide -->
      <info-guide-add-dialog
        class="mb-3 d-block d-xl-none"
        :visible="guideVisible"
        :title="$t('pages.businessGuide.otherOverheads.addDialog.guide.title')"
        :text="$t('pages.businessGuide.otherOverheads.addDialog.guide.text')"
        @click="onToggleDialogGuide"
      />
      <!-- Info Guide -->

      <!-- Ai Buddy -->
      <ai-buddy
        v-if="$breakpoint.lgAndDown"
        type="other-overheads"
        class="ai-buddy-margin-bottom d-block d-xl-none"
        @suggestion="setSuggestion"
        @click="guideVisible = false"
      />
      <!-- Ai Buddy -->

      <!-- Inner Wrapper -->
      <div class="inner-wrapper">
        <div class="grid-wrapper">
          <el-row :gutter="20">

            <!-- Left Side -->
            <el-col :md="12" :xl="7">

              <!-- Form Wrapper -->
              <div class="overheads-form-wrapper">

                <!-- Form Inner -->
                <div class="overheads-form-inner-top">

                  <!-- Form -->
                  <el-form :model="form">

                    <!-- Name -->
                    <div class="d-flex align-items-center">
                      <ib-input
                        v-model="form.name"
                        class="flex-grow-1"
                        :label="$t('fields.name')"
                      />
                      <ai-buddy-button-rounded
                        class="ai-buddy ml-2"
                        emit-event-buss-events
                        animation-key="roundedButtonAddModal"
                      />
                    </div>
                    <!-- /Name -->

                    <!-- Other Overhead Name -->
                    <el-form-item
                      class="input-inside-label icon"
                      :label="$t('pages.businessGuide.otherOverheads.addDialog.fields.typeOfOtherOverhead.title')"
                      :class="{'focused': focus.type}"
                    >

                      <!-- Symbol -->
                      <span class="symbol default" :class="form.type" />
                      <!-- /Symbol -->

                      <ib-select
                        v-model="form.niceName"
                        placeholder=" "
                        popper-class="promo-dropdown"
                        allow-create
                        :popper-append-to-body="false"
                        @change="changeType"
                      >
                        <ib-option
                          v-for="type in options.types"
                          :key="type.value"
                          :label="$t(type.label)"
                          :value="type.value"
                        >
                          <div class="detailed-option" :class="{ 'selected': type.value === form.type }">

                            <!-- Symbol & Label -->
                            <div class="flex">

                              <!-- Symbol -->
                              <img svg-inline :src="type.imgPath" class="icon-dropdown">
                              <!-- /Symbol -->

                              <!-- Label -->
                              <span
                                class="label"
                                :class="{'selected': type.value === form.type}"
                              >
                                {{ $t(type.label) }}
                              </span>
                              <!-- /Label -->

                            </div>
                            <!-- Symbol & Label -->

                          </div>
                        </ib-option>
                      </ib-select>
                    </el-form-item>
                    <!-- /Other Overhead Name -->

                    <!--                    <form-switcher />-->

                    <!-- One Time Or Constant Or Percentage of Revenue -->
                    <div class="switch-wrapper">

                      <!-- Switch -->
                      <div class="switch">

                        <!-- One Time Switch Input -->
                        <input
                          id="oneTime"
                          type="radio"
                          class="switch-input"
                          name="view"
                          value="oneTime"
                          :checked="form.oneTime"
                          @click="changeToOneTime"
                        >
                        <label
                          for="oneTime"
                          class="switch-label switch-label-off"
                          @click="changeToOneTime"
                        >
                          {{ $t('pages.businessGuide.otherOverheads.addDialog.fields.oneTime') }}
                        </label>
                        <!-- /One Time Switch Input -->

                        <!-- Constant Switch Input -->
                        <input
                          id="constant"
                          type="radio"
                          class="switch-input"
                          name="view"
                          value="constant"
                          :checked="form.constant"
                          @click="changeToConstant"
                        >
                        <label
                          for="constant"
                          class="switch-label switch-label-on"
                          :class="{ 'completed': form.oneTime === false && form.revenue === false }"
                          @click="changeToConstant"
                        >
                          {{ $t('pages.businessGuide.otherOverheads.addDialog.fields.constant') }}
                        </label>
                        <!-- /Constant Switch Input -->

                        <!-- Percentage of Revenue Switch Input -->
                        <input
                          id="revenue"
                          type="radio"
                          class="switch-input"
                          name="view"
                          value="revenue"
                          :checked="form.revenue"
                          @click="changeToRevenue"
                        >
                        <label
                          for="revenue"
                          class="switch-label switch-label-on2"
                          :class="{ 'completed': form.oneTime === false && form.constant === false }"
                          @click="changeToRevenue"
                        >
                          {{ $t('pages.businessGuide.otherOverheads.addDialog.fields.percentOfRevenue') }}
                        </label>
                        <!-- Percentage of Revenue Switch Input -->

                        <!-- Switch Selection -->
                        <span class="switch-selection" />
                        <!-- /Switch Selection -->

                      </div>
                      <!-- /Switch -->

                    </div>
                    <!-- /One Time Or Constant Or Percentage of Revenue -->

                    <!-- Amount -->
                    <el-form-item
                      v-if="form.oneTime"
                      class="currency-related-form-item"
                      :label="$t('fields.amount')"
                      :class="{'focused': focus.amount}"
                    >

                      <div class="currency-related-input amount el-input">
                        <span class="currency-symbol">{{ currencySymbol }}</span>
                        <currency-input
                          v-model="form.amount"
                          class="el-input__inner"
                          :distraction-free="false"
                          :currency="null"
                          :value-range="{min: 0, max: 999999999}"
                          :precision="{min: 0, max: 2}"
                          locale="en-US"
                        />
                      </div>
                    </el-form-item>
                    <!-- /Amount -->

                    <!-- Amount -->
                    <el-form-item
                      v-show="form.constant"
                      class="currency-related-form-item"
                      :label="$t('fields.monthlyAmount')"
                      :class="{'focused': focus.constantAmount}"
                    >
                      <div class="currency-related-input amount el-input">
                        <span class="currency-symbol">{{ currencySymbol }}</span>
                        <currency-input
                          v-model="form.constantAmount"
                          class="el-input__inner"
                          :distraction-free="false"
                          :currency="null"
                          :value-range="{min: 0, max: 999999999}"
                          :precision="{min: 0, max: 2}"
                          locale="en-US"
                        />
                      </div>
                    </el-form-item>
                    <!-- /Amount -->

                    <!-- Select revenue stream -->
                    <el-form-item
                      v-show="form.revenue"
                      class="input-inside-label revenue-stream"
                      :label="$t('pages.businessGuide.marketingBudget.addDialog.fields.selectRevenueStream')"
                      :class="{'focused' : focus.revenueStreamId }"
                    >
                      <ib-select
                        v-model="form.revenueStreamId"
                        :popper-append-to-body="false"
                        popper-class="promo-dropdown"
                        placeholder=" "
                        @focus="toggleFocus('revenueStreamId')"
                        @blur="toggleFocus('revenueStreamId')"
                        @change="changeRevenueStream"
                      >
                        <ib-option
                          v-for="revenueStream in revenueStreams"
                          :key="revenueStream.id"
                          :label="revenueStream.name"
                          :value="revenueStream.id"
                          :class="{ 'selected': revenueStream.id === form.revenueStreamId }"
                        />
                      </ib-select>
                    </el-form-item>
                    <!-- /Select revenue stream -->

                    <!-- Percentage -->
                    <el-form-item
                      v-show="form.revenue"
                      :class="{ 'focused': focus.percentageOfRevenue }"
                      class="currency-related-form-item"
                      :label="$t('percentage')"
                    >
                      <div class="currency-related-input percentage el-input">
                        <span class="currency-symbol">%</span>
                        <currency-input
                          v-model="form.percentageOfRevenue"
                          class="el-input__inner"
                          :distraction-free="false"
                          :currency="null"
                          :value-range="{min: 0, max: 100}"
                          :precision="{min: 0, max: 2}"
                          locale="en-US"
                        />
                      </div>
                    </el-form-item>
                    <!-- Percentage -->

                    <!-- Overhead Form Inner Bottom -->
                    <div class="overheads-form-inner-bottom">

                      <!-- Overhead Change & Payment Method -->
                      <div v-show="form.constant" class="group-product switches">

                        <!-- Overhead Change -->
                        <el-form-item
                          class="input-inside-label"
                          :label="$t('pages.businessGuide.otherOverheads.addDialog.fields.overheadChange')"
                          :class="{ 'focused': focus.overheadChange }"
                        >
                          <div class="input-with-select el-input">
                            <currency-input
                              v-model="form.overheadChange"
                              class="el-input__inner"
                              :distraction-free="false"
                              :currency="null"
                              :value-range="{min: 0, max: form.isOverheadChangePercentage ? 100 : form.overheadChangePrefix === '-' && form.overheadChange > form.constantAmount ? form.constantAmount : 999999999}"
                              :precision="{min: 0, max: 2}"
                              locale="en-US"
                              @focus="toggleFocus('overheadChange')"
                              @blur="toggleFocus('overheadChange')"
                            />
                          </div>
                          <ib-tooltip
                            popper-class="center"
                            :content="$t('tooltipPositiveNegative')"
                          >
                            <el-button
                              :icon="form.overheadChangePrefix === '+' ? 'el-icon-plus' : 'el-icon-minus'"
                              @click="toggleValue"
                            />
                          </ib-tooltip>
                        </el-form-item>
                        <!-- /Overhead Change -->

                        <!-- Payment Method -->
                        <el-form-item
                          class="input-inside-label"
                          label=""
                          :class="{'focused' : true}"
                        >
                          <ib-select
                            v-model="form.paymentMethod"
                            :filterable="false"
                            :popper-append-to-body="false"
                            popper-class="promo-dropdown"
                            placeholder=" "
                            @focus="toggleFocus('paymentMethod')"
                            @blur="toggleFocus('paymentMethod')"
                          >
                            <ib-option
                              v-for="paymentMethod in paymentMethods"
                              :key="paymentMethod.key"
                              :label="paymentMethod.label"
                              :value="paymentMethod.value"
                            />
                          </ib-select>
                          <ib-tooltip
                            popper-class="center"
                            :content="$t('tooltipValuesPercentages')"
                          >
                            <el-button @click="togglePercentage">
                              <i v-if="form.isOverheadChangePercentage" class="el-icon">%</i>
                              <i v-else
                                 class="currency-symbol"
                                 :class="{'symbol-adjustment': currencySymbolLength > 3 }"
                              >
                                {{ currencySymbol }}
                              </i>
                            </el-button>
                          </ib-tooltip>
                        </el-form-item>
                        <!-- /Payment Method -->

                      </div>
                      <!-- /Overhead Change & Payment Method -->

                      <!-- Start Date and End Date -->
                      <div
                        :class="{'visible': form.constant === true }"
                      >
                        <div class="group date">

                          <!-- Start Date -->
                          <el-form-item
                            v-show="form.constant"
                            class="input-inside-label yellow"
                            :label="$t('starts')"
                            :class="{'focused': focus.starts }"
                          >
                            <el-date-picker
                              v-model="form.starts"
                              popper-class="date-picker-monthly-dropdown-modal"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptions"
                              class="date-picker-monthly"
                              type="month"
                              :clearable="false"
                              :editable="false"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @focus="toggleFocus('starts')"
                              @change="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- Start Date -->

                          <!-- End Date -->
                          <el-form-item
                            v-show="form.constant"
                            class="input-inside-label yellow"
                            :label="$t('ends')"
                            :class="{'focused': focus.ends}"
                          >
                            <el-date-picker
                              v-model="form.ends"
                              :clearable="false"
                              :editable="false"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptions"
                              class="date-picker-monthly"
                              popper-class="date-picker-monthly-dropdown-modal"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @change="toggleFocus('ends')"
                              @focus="toggleFocus('ends')"
                              @blur="toggleFocus('ends')"
                            />
                          </el-form-item>
                          <!-- /End Date -->

                        </div>
                      </div>
                      <!-- /Start Date and End Date -->

                      <!-- Start Date and End Date -->
                      <div
                        :class="{'visible': form.revenue === true }"
                      >
                        <div class="group date">

                          <!-- Start Date -->
                          <el-form-item
                            v-show="form.revenue"
                            class="input-inside-label yellow"
                            :label="$t('starts')"
                            :class="{'focused': focus.starts }"
                          >
                            <el-date-picker
                              v-model="revenueStarts"
                              :clearable="false"
                              :editable="false"
                              :disabled="!form.constant"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptions"
                              class="date-picker-monthly"
                              popper-class="date-picker-monthly-dropdown-modal"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @change="toggleFocus('starts')"
                              @focus="toggleFocus('starts')"
                              @blur="toggleFocus('starts')"
                            />
                          </el-form-item>
                          <!-- Start Date -->

                          <!-- End Date -->
                          <el-form-item
                            v-show="form.revenue"
                            class="input-inside-label yellow"
                            :label="$t('ends')"
                            :class="{'focused': focus.ends}"
                          >
                            <el-date-picker
                              v-model="revenueEnds"
                              :clearable="false"
                              :editable="false"
                              :disabled="!form.constant"
                              :popper-append-to-body="false"
                              :picker-options="pickerOptions"
                              class="date-picker-monthly"
                              popper-class="date-picker-monthly-dropdown-modal"
                              type="month"
                              format="MMM, yyyy"
                              value-format="yyyy-MM-dd"
                              @change="toggleFocus('ends')"
                              @focus="toggleFocus('ends')"
                              @blur="toggleFocus('ends')"
                            />
                          </el-form-item>
                          <!-- /End Date -->

                        </div>
                      </div>
                      <!-- /Start Date and End Date -->

                      <!-- When Will It Occur -->
                      <div v-if="form.oneTime === true">
                        <el-form-item
                          class="input-inside-label yellow"
                          :label="$t('fields.whenWillItOccur')"
                          :class="{'focused': focus.whenWillItOccur === true }"
                        >
                          <el-date-picker
                            v-model="form.whenWillItOccur"
                            :clearable="false"
                            :editable="false"
                            :picker-options="pickerOptions"
                            :popper-append-to-body="false"
                            format="MMM, yyyy"
                            class="date-picker-monthly"
                            popper-class="date-picker-monthly-dropdown-modal"
                            type="month"
                            value-format="yyyy-MM-dd"
                            @change="toggleFocus('whenWillItOccur')"
                            @focus="toggleFocus('whenWillItOccur')"
                            @blur="toggleFocus('whenWillItOccur')"
                          />
                        </el-form-item>
                      </div>
                      <!-- /When Will It Occur -->

                      <!-- Advanced Settings -->
                      <!-- <el-button class="advanced">-->
                      <!--   Advanced Settings <sup>SOON</sup>-->
                      <!-- </el-button>-->
                      <!-- /Advanced Settings -->

                    </div>
                    <!-- /Overhead Form Inner Bottom -->

                  </el-form>
                  <!-- /Form -->

                </div>
                <!-- /Form Inner -->
              </div>
              <!-- /Form Wrapper -->

              <!-- Dialog Bottom -->
              <div class="my-4 my-md-0">

                <!-- Divider -->
                <ib-divider block class="my-4" />
                <!-- /Divider -->

                <el-row :gutter="10" class="d-flex">
                  <el-col :span="12">
                    <!-- Close -->
                    <ib-button class="w-100" variant="white" size="lg" font-family="regular" @click="closeDialogOnButton">
                      {{ $t('close') }}
                    </ib-button>
                    <!-- /Close -->
                  </el-col>

                  <el-col :span="12">
                    <ib-button
                      class="w-100 text-uppercase"
                      size="lg"
                      :disabled="!isAddEnabled"
                      :loading="loading"
                      @click="saveOtherOverhead"
                    >
                      {{ form.id === 0 ? $t('add') : $t('save') }}
                    </ib-button>
                  </el-col>
                </el-row>
              </div>
              <!-- /Dialog Bottom -->
            </el-col>
            <!-- /Left Side -->

            <!-- Center -->
            <el-col :md="12" :xl="7">
              <template v-if="!$store.state.idea.storyMode.forecast.otherOverheads.otherOverheads.length">
                <div class="d-flex flex-column justify-content-center align-items-center mt-5">
                  <img class="add-dialog-folder-icon" src="@/assets/img/icons/add-dialog-folder.svg" alt="">
                  <p class="add-dialog-folder-text font-outfit-regular">
                    {{ $t('createdItemsWillAppearHere') }}
                  </p>
                </div>
              </template>
              <!-- Overhead Title -->
              <!-- /Overhead Title -->

              <!-- Overhead Added -->
              <div ref="cardHolder" class="overheads-added">

                <!-- Overhead Added List -->
                <div
                  v-for="otherOverhead in $store.state.idea.storyMode.forecast.otherOverheads.otherOverheads"
                  :key="otherOverhead.id"
                >
                  <set-up-and-projections-card
                    class="mb-2"
                    :entity="otherOverhead"
                    :name="otherOverhead.name"
                    :date="otherOverheadDate(otherOverhead)"
                    :amount="otherOverheadAmount(otherOverhead)"
                    :frequency="otherOverhead.oneTime ? $t('oneTime') :$t('pages.businessGuide.monthlyAverage')"
                    :type="otherOverhead.type"
                    view="list"
                    @edit="onEditOtherOverhead"
                    @copy="onCopyOtherOverhead"
                    @delete="onDeleteOtherOverhead"
                  />
                </div>
                <!-- /Overhead Added List -->
                <erase-icon-button
                  v-if="removeButtonVisible"
                  confirm
                  :loading="removeLoadingExamples"
                  @confirm="deleteExamples"
                />
              </div>
              <!-- /Overhead Added -->

            </el-col>
            <!-- /Center -->

            <!-- Right Side -->
            <el-col :xl="10">
              <!-- Info Guide -->
              <info-guide-add-dialog
                class="mb-3 d-none d-xl-block"
                :visible="guideVisible"
                :title="$t('pages.businessGuide.otherOverheads.addDialog.guide.title')"
                :text="$t('pages.businessGuide.otherOverheads.addDialog.guide.text')"
                @click="onToggleDialogGuide"
              />
              <!-- Info Guide -->

              <!-- Ai Buddy -->
              <div class="relative">
                <ai-buddy
                  v-if="$breakpoint.lgAndUp"
                  type="other-overheads"
                  class="ai-buddy-margin-bottom d-none d-xl-block"
                  @suggestion="setSuggestion"
                  @click="handwritingSet('addOverheadExpensesSuggestion'); guideVisible = false"
                />
                <!-- Ai Buddy -->
                <div v-if="getHandwritingVisible('addOverheadExpensesSuggestion')" class="d-none d-xl-block">
                  <AddDialogAiHandwriting />
                </div>
              </div>

              <!-- Ai Buddy -->
            </el-col>
            <!-- /Right Side -->
          </el-row>
        </div>
      </div>
      <!-- /Inner Wrapper -->
    </ib-dialog-container>
    <!-- /Content Wrapper -->

  </ib-dialog>
  <!-- /Dialog Add Other Overhead -->

</template>

<script>
import AddDialogAiHandwriting from '@/views/Home/Components/AddDialogAiHandwriting.vue'
import AddDialogHeader from '@/components/_v2/Common/AddDialogHeader'
import AiBuddyButtonRounded from '@/views/Home/StoryMode/Components/AiBuddyButtonRounded.vue'
import EraseIconButton from '@/components/_v2/EraseIconButton'
import IbOption from '@/components/_v2/Select/IbOption'
import InfoGuideAddDialog from '@/views/Home/StoryMode/Components/Guide/InfoGuideAddDialog.vue'
import MixinCloseMenu from '@/mixins/closeDropdownMenuOnScroll'
import MixinCurrencies from '@/mixins/currencies'
import MixinDeleteIdeaExamples from '@/mixins/deleteIdeaExamples'
import MixinDialog from '@/mixins/dialog'
import MixinGamification from '@/mixins/gamification'
import MixinGuide from '@/mixins/guide'
import MixinHandwriting from '@/mixins/handwriting'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import SetUpAndProjectionsCard from '@/views/Home/StoryMode/Components/Cards/SetUpAndProjectionsCard'

import { mapActions, mapGetters } from 'vuex'
import AiBuddy from '@/views/Home/StoryMode/Components/AiBuddy/AiBuddy.vue'

export default {
  name: 'DialogAddOtherOverhead',

  components: {
    AddDialogAiHandwriting,
    AiBuddy,
    AiBuddyButtonRounded,
    InfoGuideAddDialog,
    EraseIconButton,
    AddDialogHeader,
    IbOption,
    SetUpAndProjectionsCard
  },

  mixins: [
    MixinCurrencies,
    MixinDialog,
    MixinGamification,
    MixinGuide,
    MixinCloseMenu,
    MixinDeleteIdeaExamples,
    MixinIdeaRoles,
    MixinHandwriting
  ],

  props: {
    dialogAddOtherOverheadVisible: {
      type: Boolean,
      default: false
    },
    otherOverheadAction: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    }
  },

  data () {
    const maxOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').format('x')
    const minOccurDate = this.$moment(this.$store.state.idea.ideaSettings.startingFrom).format('x')

    return {
      loading: false,
      removeLoadingExamples: false,
      guideVisible: false,
      amountRevenue: 0,
      form: {
        id: 0,
        ideaId: this.$store.state.idea.id,
        type: '',
        name: '',
        niceName: '',
        oneTime: true,
        constant: false,
        revenue: false,
        amount: null,
        constantAmount: null,
        percentageOfRevenue: null,
        overheadChange: null,
        overheadChangeNice: null,
        paymentMethod: '',
        isOverheadChangePercentage: false,
        overheadChangePrefix: '+',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom,
        starts: this.$store.state.idea.ideaSettings.startingFrom,
        // ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'y').format('YYYY-MM') + '-01',
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD'),
        revenueStreamId: null,
        revenueStarts: '',
        revenueEnds: ''
      },
      focus: {
        type: false,
        overheadChange: false,
        paymentMethod: false,
        whenWillItOccur: false,
        starts: false,
        ends: false,
        revenueStreamId: false,
        isOverheadChangePercentage: false
      },
      pickerOptions: {
        disabledDate (time) {
          return time.getTime() < minOccurDate || time.getTime() >= maxOccurDate
        }
      },
      paymentMethods: [{
        key: 1,
        label: this.$t('monthly'),
        value: 'monthly'
      }, {
        key: 2,
        label: this.$t('quarterly'),
        value: 'quarterly'
      }, {
        key: 3,
        label: this.$t('annually'),
        value: 'annually'
      }],
      paymentOption: '$'
    }
  },

  computed: {
    ...mapGetters('idea', ['getHasItemsFromTemplate']),

    isAddEnabled () {
      let enabled = this.form.type && this.form.name && this.form.amount && this.form.whenWillItOccur

      if (this.form.constant) {
        enabled = this.form.constantAmount && this.form.type && this.form.paymentMethod && this.form.starts && this.form.ends
      }

      if (this.form.revenue) {
        enabled = this.form.type && this.form.revenueStreamId && this.form.percentageOfRevenue && this.form.starts && this.form.starts
      }

      return enabled
    },

    revenueStreams () {
      return this.$store.state.idea.storyMode.forecast.revenueStreams.revenueStreams
    },

    isDialogOpen () {
      return this.dialogAddOtherOverheadVisible
    },

    revenueStarts () {
      return !this.form.constant ? this.form.revenueStarts : this.form.starts
    },

    revenueEnds () {
      return !this.form.constant ? this.form.revenueEnds : this.form.ends
    },

    removeButtonVisible () {
      return this.canEdit && this.getHasItemsFromTemplate('getOtherOverheads')
    }
  },

  watch: {
    'form.constant': function () {
      this.setOtherOverheadPeriod()
    },

    'form.revenueStreamId': function () {
      this.setOtherOverheadPeriod()
    }
  },

  methods: {
    ...mapActions('idea', [
      'deleteStoryModeOtherOverhead'
    ]),

    setSuggestion (suggestion) {
      this.form.name = suggestion.name
      this.form.constantAmount = Number(suggestion.amount)
      this.form.paymentMethod = 'monthly'
      this.form.oneTime = false
      this.form.isOverheadChangePercentage = false
      this.form.constant = true
      this.form.type = suggestion.cost_type
      this.form.niceName = suggestion.cost_type
      this.focus.type = true
    },

    onToggleDialogGuide () {
      this.guideVisible = !this.guideVisible
    },

    closeDialogOnButton () {
      this.$emit('close-dialog-on-button')
    },

    closeDialog () {
      this.$emit('close-dialog-add-other-overhead')
    },

    closedDialog () {
      this.resetForm()
      this.guideVisible = false
    },

    toggleFocus (input) {
      if (typeof this.focus[input] === 'undefined') {
        this.$set(this.focus, input)
      }

      this.focus[input] = !this.focus[input]
      if (this.form[input] !== null && this.form[input] !== '') this.focus[input] = true
    },

    changeToOneTime () {
      if (this.form.oneTime === false) {
        this.form.oneTime = true
        this.form.constant = false
        this.form.revenue = false
        this.form.constantAmount = null
        this.form.overheadChange = null
        this.form.paymentMethod = ''
        this.form.whenWillItOccur = this.$store.state.idea.ideaSettings.startingFrom
        this.form.starts = this.$store.state.idea.ideaSettings.startingFrom
        this.form.revenueStreamId = null
        this.form.revenuePercentage = ''
      }
    },

    changeToConstant () {
      if (this.form.oneTime === true || this.form.revenue === true) {
        this.form.constant = true
        this.form.oneTime = false
        this.form.revenue = false
        this.form.paymentMethod = 'monthly'
        this.form.amount = null
        this.form.overheadChangePrefix = '+'
        this.form.starts = this.$store.state.idea.ideaSettings.startingFrom
        this.form.whenWillItOccur = this.$store.state.idea.ideaSettings.startingFrom
        this.form.revenueStreamId = null
        this.form.percentageOfRevenue = null
      }
    },

    changeToRevenue () {
      if (this.form.oneTime === true || this.form.constant === true) {
        this.form.revenue = true
        this.form.oneTime = false
        this.form.constant = false
        this.form.paymentMethod = ''
        this.form.amount = null
        this.form.overheadChange = null
        this.form.constantAmount = null
        this.form.percentageOfRevenue = null
        this.form.starts = this.$store.state.idea.ideaSettings.startingFrom
        this.form.whenWillItOccur = this.$store.state.idea.ideaSettings.startingFrom
      }
    },

    changeRevenueStream (value) {
      for (let i = 0; i < this.revenueStreams.length; i++) {
        if (this.revenueStreams[i].id === value) {
          this.form.revenueStreamId = this.revenueStreams[i].id
        }
      }
      this.checkInputLabels()
    },

    saveOtherOverhead () {
      this.loading = true
      if (this.form.id === 0) {
        this.addOtherOverhead()
      } else {
        this.updateOtherOverhead()
      }
    },

    otherOverheadDate (otherOverhead) {
      if (otherOverhead.oneTime) {
        return this.getFormattedDate(otherOverhead.whenWillItOccur)
      }

      return `${this.getFormattedDate(otherOverhead.starts)} - ${this.getFormattedDate(otherOverhead.ends)}`
    },

    otherOverheadAmount (otherOverhead) {
      if (otherOverhead.average && otherOverhead.oneTime === false) {
        return this.getFormattedAmount(otherOverhead.average)
      }

      return this.getFormattedAmount(otherOverhead.amount)
    },

    resetForm () {
      this.form = {
        id: 0,
        ideaId: this.$store.state.idea.id,
        type: '',
        name: '',
        niceName: '',
        revenueStreamId: null,
        amount: null,
        constantAmount: null,
        overheadChange: null,
        overheadChangeNice: null,
        oneTime: true,
        constant: false,
        revenue: false,
        percentageOfRevenue: null,
        paymentMethod: '',
        isOverheadChangePercentage: false,
        overheadChangePrefix: '+',
        whenWillItOccur: this.$store.state.idea.ideaSettings.startingFrom,
        starts: this.$store.state.idea.ideaSettings.startingFrom,
        ends: this.$moment(this.$store.state.idea.ideaSettings.startingFrom).add(this.$store.state.idea.ideaSettings.planningFor, 'Y').subtract(1, 'M').format('YYYY-MM-DD')
      }
      this.focus = {
        type: false,
        overheadChange: false,
        paymentMethod: false,
        whenWillItOccur: false,
        starts: false,
        ends: false,
        revenueStreamId: false,
        isOverheadChangePercentage: false,
        overheadChangePrefix: false
      }
      this.checkInputLabels()
    },

    resetGuide () {
      if (this.$store.getters['idea/getOtherOverheads'].length === 0) {
        this.guideVisible = false
      }
    },

    addOtherOverhead () {
      if (!this.form.constant && !this.form.oneTime) {
        this.form.starts = this.form.revenueStarts
        this.form.ends = this.form.revenueEnds
      }

      this.$http.post('story-mode/forecast/other-overhead/other-overhead', this.form)
        .then((response) => {
          if (response.status === 201) {
            this.resetGuide()
            this.$store.commit('idea/addOtherOverhead', response.data.payload.otherOverhead)
            this.handleGamification()
            this.resetForm()
          }
          this.$emit('on-update-other-overheads-view')
        }).finally(() => {
          this.loading = false
          this.resetForm()
          this.scrollToTop()
        })
    },

    updateOtherOverhead () {
      if (!this.form.constant && !this.form.oneTime) {
        this.form.starts = this.form.revenueStarts
        this.form.ends = this.form.revenueEnds
      }

      this.$http.put(`story-mode/forecast/other-overhead/other-overhead/${this.form.id}`, this.form)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('idea/updateOtherOverhead', response.data.payload.otherOverhead)
            this.resetForm()
          }
          this.$emit('on-update-other-overheads-view')
        }).finally(() => {
          this.loading = false
          this.resetForm()
          this.scrollToTop()
        })
    },

    checkInputLabels () {
      this.checkInputLength('type')
      this.checkInputLength('whenWillItOccur')
      this.checkInputLength('paymentMethod')
      this.checkInputLength('overheadChange')
      this.checkInputLength('starts')
      this.checkInputLength('ends')
      this.checkInputLength('whenWillItOccur')
      this.checkInputLength('revenueStreamId')
      this.checkInputLength('isOverheadChangePercentage')
    },

    checkInputLength (input) {
      this.focus[input] = !!this.form[input]
    },

    getFormattedDate (date) {
      return this.$moment(date).format('MMM YYYY')
    },

    openDialog () {
      this.openGuideDialog('addOtherOverheadGuide')
      if (this.otherOverheadAction) {
        this.copyOtherOverheadToForm(this.otherOverheadAction)
        this.form.ideaId = this.$store.state.idea.id
        this.revenueStreamId = this.otherOverheadAction.revenueStreamId
      } else {
        this.form.overheadChangePrefix = '+'
      }
      this.checkInputLabels()
    },

    copyOtherOverheadToForm (otherOverhead) {
      this.resetForm()
      this.form = Object.assign({}, otherOverhead)
      this.form.ideaId = this.$store.state.idea.id
      this.revenueStreamId = otherOverhead.revenueStreamId

      for (let i = 0; i < this.options.types.length; i++) {
        if (this.form.type === this.options.types[i].value) {
          this.form.niceName = this.$t(this.options.types[i].label)
        }
      }

      this.checkInputLabels()
      this.scrollToTop()
    },

    onEditOtherOverhead (otherOverhead) {
      this.copyOtherOverheadToForm(otherOverhead)
    },

    onCopyOtherOverhead (otherOverhead) {
      this.copyOtherOverheadToForm(otherOverhead)
      this.form.id = 0
    },

    onDeleteOtherOverhead (otherOverhead) {
      this.deleteStoryModeOtherOverhead(otherOverhead.id)
        .then((response) => {
          this.$emit('on-update-other-overheads-view')
        })
    },

    toggleValue () {
      this.signSwitch = !this.signSwitch
      if (!this.signSwitch) {
        this.form.overheadChangePrefix = '+'
      } else {
        this.form.overheadChangePrefix = '-'
      }
    },

    togglePercentage () {
      this.form.isOverheadChangePercentage = !this.form.isOverheadChangePercentage
    },

    setOtherOverheadPeriod () {
      if (this.form.revenueStreamId && !this.form.constant && !this.form.oneTime) {
        const revenueStreamObject = this.getRevenueStream(this.form.revenueStreamId)

        this.form.revenueStarts = this.$moment(revenueStreamObject.starts).format('YYYY-MM-DD')
        this.form.revenueEnds = this.$moment(revenueStreamObject.ends).format('YYYY-MM-DD')
      }
    },

    changeType (value) {
      for (let i = 0; i < this.options.types.length; i++) {
        if (value === this.options.types[i].value) {
          this.form.type = this.options.types[i].value
          // this.form.name = this.$t(this.options.types[i].label)
          this.toggleFocus('type')
          // this.toggleFocus('name')
        } else {
          this.form.type = value
          this.toggleFocus('type')
          // this.toggleFocus('name')
        }
      }
    },

    getRevenueStream (revenueStreamId) {
      const index = this.getIdea().storyMode.forecast.revenueStreams.revenueStreams.indexOf(
        this.getIdea().storyMode.forecast.revenueStreams.revenueStreams.find(revenueStreamToFind => revenueStreamToFind.id === revenueStreamId)
      )

      return this.getIdea().storyMode.forecast.revenueStreams.revenueStreams[index]
    },

    async deleteExamples () {
      try {
        this.removeLoadingExamples = true
        const response = await this.deleteIdeaExamples('other-overheads')
        this.$emit('on-update-other-overheads-view')
        const { type, ids } = response
        this.deleteFromState({ type, ids })
        this.resetForm()
        this.removeLoadingExamples = false
      } catch (error) {
        this.removeLoadingExamples = false
      }
    }
  }
}
</script>

<style scoped lang="scss">

.add-dialog-folder-icon {
  width: 80px;
  @include media-breakpoint-up($xxl) {
    width: 100px;
  }
}

.add-dialog-folder-text {
  font-size: 14px;
  @include media-breakpoint-up($xxl) {
    font-size: 16px;
  }
}

</style>
