<template>
  <!-- Financial Plan -->
  <div id="financial-plan">
    <!-- Container -->
    <div class="container-1200">

      <!-- Steps navigation -->
      <div class="steps-navigation-wrapper">
        <div class="steps">
          <ib-main-navigation :steps="steps" />
        </div>

        <!--        <ib-main-navigation-button route-name="financial-plan-settings" />-->
        <div class="steps-navigation-buttons-wrapper">
          <financial-plan-export />
          <financial-plan-share />
        </div>
      </div>
      <!-- /Steps navigation -->

      <!-- Content -->
      <div class="content">

        <!-- Page Header -->
        <header class="page-header">

          <!-- Dashboard Header -->
          <div id="dashboard-header">
            <div class="header-wrapper d-flex justify-content-end justify-content-md-between">

              <!-- Title -->
              <h1 class="d-none d-md-block">
                {{ $t($route.meta.subtitle) }}
              </h1>
              <!-- /Title -->
              <!-- Header Right -->
              <div
                v-if="$route.name !== 'financial-plan-settings'"
                class="header-right"
              >
                <template v-if="isOverviewPage && getHandWritings.financialPlanOverview">
                  <div class="d-none d-xl-flex">
                    <ib-handwriting
                      :text="$t('clickToAddFinancialItem')"
                      tag="span"
                      style="font-size:24px;"
                    >
                      <template #arrow>
                        <img :src="require('@/assets/img/util/arrow-right.svg')" class="mb-3" width="80" alt="arrow">
                      </template>
                    </ib-handwriting>
                  </div>
                </template>
                <template v-if="buttonsVisible">
                  <!--                  <ai-buddy-button-->
                  <!--                    responsive-->
                  <!--                    height="43px"-->
                  <!--                    @click="openAiBuddy"-->
                  <!--                  />-->
                  <info-button
                    responsive
                    class="ml-2"
                    height="43px"
                    :active="infoButtonActive"
                    @click="openInfo"
                  />
                </template>
                <add-new-dropdown-button
                  v-if="isAddEditDropdownShow"
                  :options="addNewDropdownOptions"
                  :disabled="!canEdit"
                  @clickButton="setHandWriting({key: 'financialPlanOverview', value: false})"
                />
                <add-edit-button
                  v-if="!isAddEditDropdownShow"
                  class="ml-2"
                />
              </div>
              <!-- /Header Right -->
            </div>
          </div>
          <!-- /Dashboard Header -->

          <!-- Divider -->
          <hr>
          <!-- /Divider -->

        </header>
        <!-- /Page Header -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Content -->
        <div>
          <loader v-if="loading" />
          <transition
            v-else
            name="fade"
            mode="out-in"
          >
            <router-view />
          </transition>
        </div>
        <!-- /Content -->

        <add-new-dropdown-dialogs
          v-if="isAddEditDropdownShow"
          :options="addNewDropdownOptions"
          :disabled="!canEdit"
          @loading="loading = $event.value"
        />

        <bottom-back-block v-if="$route.name !== 'financial-plan-settings'" />
      </div>
      <!-- /Content -->

    </div>
    <!-- /Container -->

    <!-- Dialog Share Financial Plan -->
    <dialog-share
      :title="$t('dialogs.share.shareOverview')"
      type="financialPlan"
      :visible="dialogShareOverviewVisible"
      :token="sharedOverviewToken"
      @close="onCloseShareOverview"
    />
    <!-- /Dialog Share Financial Plan -->

    <!-- Dialog Export Financial Plan -->
    <dialog-export-financial-plan
      :visible="dialogExportVisible"
      :format="generateFormat"
      @close="dialogExportVisible = false"
    />
    <!-- /Dialog Export Financial Plan -->

    <!-- Dialog Remove Examples -->
    <dialog-remove-examples
      :visible="dialogRemoveExamplesVisible"
      :loading="removeExamplesLoading"
      @no="dialogRemoveExamplesVisible = false"
      @yes="deleteExamples"
    />
    <!-- /Dialog Remove Examples -->

  </div>
  <!-- /Financial Plan -->
</template>

<script>
import AddEditButton from '@/views/Home/StoryMode/FinancialPlan/Components/AddEditButton.vue'
import AddNewDropdownButton from '@/views/Home/StoryMode/Components/AddNewDropdownButton.vue'
import AddNewDropdownDialogs from '@/views/Home/StoryMode/Components/AddNewDropdownDialogs.vue'
// import AiBuddyButton from '@/views/Home/StoryMode/Components/AiBuddyButton.vue'
import BottomBackBlock from '@/components/_v2/Common/BottomBackBlock'
import DialogExportFinancialPlan from '@/views/Home/StoryMode/FinancialPlan/Dialogs/DialogExportFinancialPlan.vue'
import DialogRemoveExamples from '@/views/Home/StoryMode/Components/DialogRemoveExamples'
import DialogShare from '@/views/Home/Components/Dialogs/DialogShare.vue'
import EventBus from '@/event-bus'
import FinancialPlanExport from '@/views/Home/StoryMode/FinancialPlan/Components/FinancialPlanExport.vue'
import FinancialPlanShare from '@/views/Home/StoryMode/FinancialPlan/Components/FinancialPlanShare.vue'
import IbHandwriting from '@/components/_v2/IbHandwriting'
import IbMainNavigation from '@/components/_v2/IbMainNavigation'
import InfoButton from '@/views/Home/StoryMode/Components/InfoButton.vue'
import Loader from '@/components/Loader.vue'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinEraseTemplate from '@/mixins/eraseTemplate'
import MixinHeaderScroll from '@/mixins/headerScroll'
import MixinIdeaRoles from '@/mixins/permissions/ideaRoles'
import MixinUserSubscription from '@/mixins/permissions/userSubscription'
import addNewDropdownOptions from '@/data/financialPlanOverviewAddNewDropdownOptions'
import overviewOptions from '@/data/overviewOptions'

import { mapGetters, mapMutations, mapState } from 'vuex'
import { steps } from '@/data/financialPlanNavigationsSteps'

export default {
  name: 'FinancialPlan',

  components: {
    InfoButton,
    // AiBuddyButton,
    DialogShare,
    DialogExportFinancialPlan,
    FinancialPlanShare,
    FinancialPlanExport,
    Loader,
    AddNewDropdownButton,
    AddEditButton,
    AddNewDropdownDialogs,
    BottomBackBlock,
    DialogRemoveExamples,
    IbMainNavigation,
    IbHandwriting
  },

  mixins: [
    MixinApiCalculations,
    MixinEraseTemplate,
    MixinIdeaRoles,
    MixinUserSubscription,
    MixinHeaderScroll
  ],

  beforeRouteEnter (to, from, next) {
    next(vm => {
      const protectedRoutes = [
        'financial-plan-expenses-direct-costs',
        'financial-plan-expenses-marketing-costs',
        'financial-plan-expenses-other-overheads'
      ]

      if (vm.subscriptionIsFreeTrial && protectedRoutes.includes(vm.$router.currentRoute.name)) next({ name: 'home' })
    })
  },

  data () {
    return {
      loading: false,
      hasItemsFromTemplate: false,
      dialogRemoveExamplesVisible: false,
      removeExamplesLoading: false,
      shareOverviewAction: null,
      overviewElements: overviewOptions,
      steps,
      addNewDropdownOptions,

      dialogExportVisible: false,
      generateFormat: '',

      dialogShareOverviewVisible: false,
      shareLoading: false,
      sharedOverviewToken: '',
      infoButtonActive: false
    }
  },

  computed: {
    ...mapState(['idea']),
    ...mapGetters('theme', ['getHandWritings']),

    isLoaded () {
      return Object.values(this.loaded).every(item => item === true)
    },

    isOverviewPage () {
      return this.$route.name === 'financial-plan-overview'
    },

    isAddEditDropdownShow () {
      return ['financial-plan-overview', 'financial-plan-expenses-total-costs', 'financial-plan-profit-and-cash-flow'].includes(this.$route.name)
    },

    buttonsVisible () {
      return !['financial-plan-overview', 'financial-plan-expenses-total-costs'].includes(this.$route.name)
    }
  },

  watch: {
    $route () {
      this.hasItemsFromTemplate = false
    }
  },

  mounted () {
    EventBus.$on('on-delete', () => {
      this.dialogRemoveExamplesVisible = true
    })

    EventBus.$on('open-info-first-time', (payload) => {
      this.infoButtonActive = payload
    })

    EventBus.$on('close-info-first-time-close-btn', () => {
      this.infoButtonActive = false
    })

    EventBus.$on('on-export', (payload) => {
      const { format, dialogVisible } = payload

      this.generateFormat = format
      this.dialogExportVisible = dialogVisible
    })

    EventBus.$on('on-share', (payload) => {
      const { token } = payload

      this.sharedOverviewToken = token
      this.dialogShareOverviewVisible = true
    })

    EventBus.$on('remove-examples-loading', (payLoad) => {
      this.removeExamplesLoading = payLoad
      if (!payLoad) {
        this.dialogRemoveExamplesVisible = false
      }
    })

    // ToDo Move every method on appropriate view
    this.getProfitAndCashFlow()
    this.getTotalCosts()
    this.getStartupCosts()
    this.getStaffAndPayroll()
    this.getFinancing()
    this.getRevenueStreams()
    this.getDirectCosts()
    this.getMarketingBudget()
    this.getOtherOverheads()
  },

  methods: {
    ...mapMutations('ui', [
      'setDialogUpgradeState'
    ]),
    ...mapMutations('theme', ['setHandWriting']),

    onCloseShareOverview () {
      this.dialogShareOverviewVisible = false
      this.shareOverviewAction = null
    },

    deleteExamples () {
      EventBus.$emit('remove-examples')
    },

    openAiBuddy () {
      EventBus.$emit('open-ai-buddy')
    },

    openInfo () {
      if (this.infoButtonActive) {
        EventBus.$emit('open-info-first-time', false)
        EventBus.$emit('close-info-first-time')
        this.infoButtonActive = false

        return
      }
      EventBus.$emit('open-info')
    }
  }
}
</script>

<style lang="scss" scoped>
.steps-navigation-wrapper {
  display: none;

  @include media-breakpoint-up($md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 36px;
  }

  @include media-breakpoint-up($xl) {
    flex-direction: row;
    height: 50px;
  }
}
.steps {
  display: flex;
  flex-grow: 1;
}
.steps-navigation-buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
  min-width: 20%;
  margin-top: 20px;
  padding-left: 10px;

  @include media-breakpoint-up($md) {
    column-gap: 10px;
  }

  @include media-breakpoint-up($xl) {
    margin: 0;
  }
}
</style>
